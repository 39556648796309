import styled from "styled-components";

export const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-900);
  height: var(--NavHeight);
  width: 100%;
  position: fixed;
  top: 0;

  z-index: 1000;

  @keyframes top {
    0% {
      top: 0;
      transform: rotate(0);
    }
    50% {
      top: 9px;
      transform: rotate(0);
    }
    100% {
      top: 9px;
      transform: rotate(45deg);
    }
  }

  @keyframes top-2 {
    0% {
      top: 9px;
      transform: rotate(45deg);
    }
    50% {
      top: 9px;
      transform: rotate(0deg);
    }
    100% {
      top: 0;
      transform: rotate(0deg);
    }
  }

  @keyframes bottom {
    0% {
      bottom: 0;
      transform: rotate(0);
    }
    50% {
      bottom: 9px;
      transform: rotate(0);
    }
    100% {
      bottom: 9px;
      transform: rotate(135deg);
    }
  }

  @keyframes bottom-2 {
    0% {
      bottom: 9px;
      transform: rotate(135deg);
    }
    50% {
      bottom: 9px;
      transform: rotate(0);
    }
    100% {
      bottom: 0;
      transform: rotate(0);
    }
  }

  @keyframes scaled {
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes scaled-2 {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  .logoMobile {
    display: none;
  }

  .hamburguer {
    display: none;

    .circleHamb {
      border: 3px solid #aaaaf0;
      border-radius: 50%;
    }
    span {
      display: block;
      width: 100%;
      border-radius: 3px;
      height: 2px;
      background: #aaaaf0;
      transition: all 0.3s;
      position: relative;
    }
    span + span {
      margin-top: 0.7rem; // espaçamento entre as barras
    }
  }
  .true span:nth-child(1) {
    animation: ease 0.5s top forwards;
  }

  .false span:nth-child(1) {
    animation: ease 0.5s top-2 forwards;
  }

  .true span:nth-child(2) {
    animation: ease 0.5s scaled forwards;
  }

  .false span:nth-child(2) {
    animation: ease 0.5s scaled-2 forwards;
  }

  .true span:nth-child(3) {
    animation: ease 0.5s bottom forwards;
  }

  .false span:nth-child(3) {
    animation: ease 0.5s bottom-2 forwards;
  }
  .caixaMenu {
    display: none;
  }

  .navegation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 136.7rem;
    ul {
      display: flex;
      list-style: none;
      a {
        text-decoration: none;
      }
      li {
        color: var(--Primary-200);
        font-family: "Work Sans", sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        cursor: pointer;
      }
      .active {
        color: var(--Primary-100);
        font-weight: 700;
        border-width: 0 0 1px 0;
        border-color: #5fce1d;
        border-style: solid;
        padding-bottom: 0.2rem;
      }
    }
    .menu1 {
      gap: 2.4rem;
    }
    .menu2 {
      display: flex;
      gap: 4.8rem;
      align-items: center;
      .premium {
        color: #5fce1d;
        font-weight: 700;
        text-decoration: none;
      }

      ul {
        display: flex;
        gap: 2.4rem;
        align-items: center;
        li {
          font-family: "Work Sans", sans-serif;
          font-size: 1.6rem;
          font-weight: 500;
          color: var(--Primary-200);

          a {
            text-decoration: none;
            color: var(--Primary-200);
          }

          .buttonSignUp {
            font-family: "Work Sans", sans-serif;
            font-weight: 500;
            font-size: 1.6rem;
            color: var(--Primary-200);
            display: flex;
            align-items: center;
            padding: 1.2rem 2.4rem;
            gap: var(--Scale-2);
            border-radius: var(--Radius-md);
            border: 1px solid var(--Primary-200);
            background-color: transparent;
            cursor: pointer;
          }
        }
      }
    }
  }

  .menu2Mobile {
    display: none;
  }

  @media (max-width: 1553px) {
    .navegation {
      width: 90%;
    }
  }
  @media (max-width: 750px) {
    padding: 0 16px 0 16px;
    justify-content: start;

    .menu2Mobile {
      margin-left: auto;
      display: flex;
      gap: 4.8rem;
      align-items: center;
      list-style: none;
      .premium {
        color: #5fce1d;
        font-weight: 700;
        text-decoration: none;
        font-size: 1.6rem;
      }

      ul {
        display: flex;
        gap: 2.4rem;
        align-items: center;
        list-style: none;
        li {
          font-family: "Work Sans", sans-serif;
          font-size: 1.6rem;
          font-weight: 500;
          color: var(--Primary-200);

          a {
            text-decoration: none;
            color: var(--Primary-200);
          }

          .buttonSignUp {
            font-family: "Work Sans", sans-serif;
            font-weight: 500;
            font-size: 1.6rem;
            color: var(--Primary-200);
            display: flex;
            align-items: center;
            padding: 1.2rem 2.4rem;
            gap: var(--Scale-2);
            border-radius: var(--Radius-md);
            border: 1px solid var(--Primary-200);
            background-color: transparent;
            cursor: pointer;
          }
        }
      }
    }
    .logoMobile {
      display: block;
    }
    .navegation {
      display: none;
    }
    .hamburguer {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .btn {
      width: 2.4rem;
      cursor: pointer;
    }
    .tittleFrankstein {
      position: relative;
      top: -0.3rem;
      color: white;
      font-family: "Work Sans", sans-serif;
    }
    .caixaMenu {
      padding: 0;
      /* padding: 6.4rem 1.6rem 4rem 1.6rem; */
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 6.4rem;
      /* align-items: center; */
      position: fixed;
      top: 8rem;
      left: 0;
      width: 60vw;
      min-width: 32.8rem;
      height: calc(100vh - var(--NavHeight));
      z-index: 999;
      background-color: var(--grey-900);
      overflow: hidden;
      transition: all 0.5s;

      .divFlexCaixaMenu {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
      }
      .botaoAssinar {
        display: flex;
        padding: 12px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--Scale-2, 16px);
        border-radius: var(--Radius-md, 8px);
        border: 1px solid var(--Primary-200, #aaaaf0);
        color: var(--Primary-200, #aaaaf0);
        text-align: center;

        /* Text/Large/Regular */
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
      }
      a {
        // border: 1px solid red;

        width: 100%;
        text-decoration: none;
        height: 100%;
        button {
          border: none;
          background-color: transparent;
          font-family: "Work Sans", sans-serif;
          font-size: 1.6rem;
          cursor: pointer;
          letter-spacing: 0.07rem;
          text-align: center;
          color: var(--Primary-200, #aaaaf0);
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
`;
