import React, { useEffect, useRef } from "react";
import { StyledAlerta } from "../styles/Alerta";
import cross from "../assets/cross.svg";
import disk from "../assets/disk.svg";

import { useState } from "react";
import AlertCard from "../components/AlertCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Alerta({ dados, tagAlerta, setTagAlerta }) {
  const [alertOpen, setAlertOpen] = useState(tagAlerta ? true : false);
  const [suggestionName, setSuggestionName] = useState("");
  const [suggestionContestValue, setSuggestionContestValue] = useState("");
  const [suggestionLocationValue, setSuggestionLocationValue] = useState("");
  const [suggestionOfficeValue, setSuggestionOfficeValue] = useState(
    tagAlerta ? tagAlerta : ""
  );

  // ESTADOS
  const [pesquisaEstado, setPesquisaEstado] = useState("");
  const [estadoSelecionado, setEstadoSelecionado] = useState(null);
  const [showList, setShowList] = useState(false);

  // CIDADES
  const [listaDeCidades, setListaDeCidades] = useState([]);
  const [listaDeCidadesName, setListaDeCidadesName] = useState([]);

  const [pesquisaCidade, setPesquisaCidade] = useState("");
  const [cidadeSelecionada, setCidadeSelecionada] = useState(null);
  const [showListCidade, setShowListCidade] = useState(false);

  // CARGOS
  const [listaDeCargos, setListaDeCargos] = useState([]);
  const [listaDeCargosName, setListaDeCargosName] = useState([]);
  const [showListCargos, setShowListCargos] = useState(false);

  // CONCURSOS
  const [listaDeConcursos, setListaDeConcursos] = useState([]);
  const [listaDeConcursosName, setListaDeConcursosName] = useState([]);
  const [showListConcursos, setShowListConcursos] = useState(false);

  const [recarregar, setRecarregar] = useState(false);
  const [dadosSecond, setDadosSecond] = useState(dados);

  const [popUp, setPopUp] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const URL = process.env.REACT_APP_API_URL;
  const Token = process.env.REACT_APP_TOKEN;

  const refCargos = useRef(null);
  const refConcursos = useRef(null);
  const refEstados = useRef(null);
  const refCidades = useRef(null);

  const cargosOutside = (event) => {
    if (refCargos.current && !refCargos.current.contains(event.target)) {
      setShowListCargos(false);
    }
  };
  const concursosOutside = (event) => {
    if (refConcursos.current && !refConcursos.current.contains(event.target)) {
      setShowListConcursos(false);
    }
  };

  const estadosOutside = (event) => {
    if (refEstados.current && !refEstados.current.contains(event.target)) {
      setShowList(false);
    }
  };
  const cidadesOutside = (event) => {
    if (refCidades.current && !refCidades.current.contains(event.target)) {
      setShowListCidade(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", cargosOutside);
    return () => {
      document.removeEventListener("mousedown", cargosOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", concursosOutside);
    return () => {
      document.removeEventListener("mousedown", concursosOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", estadosOutside);
    return () => {
      document.removeEventListener("mousedown", estadosOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", cidadesOutside);
    return () => {
      document.removeEventListener("mousedown", cidadesOutside);
    };
  }, []);

  useEffect(() => {
    async function requisicaoParaPegarDados(email) {
      const response = await fetch(`${URL}/user/users/?emails=${email}`, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      });
      const data = await response.json();

      return data;
    }

    if (!user) {
      return;
    } else {
      requisicaoParaPegarDados(user.email).then((data) => {
        setDadosSecond(data.results[0]);
      });
    }
  }, [user, Token, URL, recarregar]);

  useEffect(() => {
    document.title = "Alertas - Alô Concursos";
    return () => {
      setTagAlerta && setTagAlerta(null);
    };
  }, []);

  useEffect(() => {
    async function requisicao(apiLink, type) {
      await fetch(apiLink, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (type === "officeTag") {
            setListaDeCargos(response);
          }
          if (type === "contestTag") {
            setListaDeConcursos(response);
          }
        });
    }

    requisicao(`${URL}/office/top-tags/`, "officeTag");
    requisicao(`${URL}/contest/top-tags/`, "contestTag");
  }, []);

  useEffect(() => {
    setListaDeCargosName(listaDeCargos.map((item) => item.name));
  }, [listaDeCargos]);

  useEffect(() => {
    setListaDeConcursosName(listaDeConcursos.map((item) => item.name));
  }, [listaDeConcursos]);

  useEffect(() => {
    async function requisicao(apiLink, type) {
      await fetch(apiLink, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (type === "location") {
            setListaDeCidades(response.results);
          }
        });
    }

    let estadoGet = obterSigla(estadoSelecionado);

    requisicao(
      `${URL}/location/location/?limit=1000&state=${estadoGet}`,
      "location"
    );
  }, [estadoSelecionado, URL, Token]);

  useEffect(() => {
    setListaDeCidadesName(listaDeCidades.map((item) => item.name));
  }, [listaDeCidades]);

  const estadosBrasil = [
    "Acre",
    "Alagoas",
    "Amapá",
    "Amazonas",
    "Bahia",
    "Ceará",
    "Distrito Federal",
    "Espírito Santo",
    "Goiás",
    "Maranhão",
    "Mato Grosso",
    "Mato Grosso do Sul",
    "Minas Gerais",
    "Pará",
    "Paraíba",
    "Paraná",
    "Pernambuco",
    "Piauí",
    "Rio de Janeiro",
    "Rio Grande do Norte",
    "Rio Grande do Sul",
    "Rondônia",
    "Roraima",
    "Santa Catarina",
    "São Paulo",
    "Sergipe",
    "Tocantins",
  ];
  const siglasEstados = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  function obterSigla(estado) {
    const index = estadosBrasil.indexOf(estado);

    if (index !== -1) {
      return siglasEstados[index];
    }
  }

  const handleStateClick = (estado) => {
    setEstadoSelecionado(estado);
    setPesquisaEstado(estado);
    setShowList(false);
  };
  const handleInputBlur = () => {
    // Delay para garantir que o clique no item da lista seja registrado antes de ocultar
    setTimeout(() => setShowList(false), 150);
  };

  const handleCityClick = (cidade) => {
    setCidadeSelecionada(cidade);
    setPesquisaCidade(cidade);
    setShowListCidade(false);
  };

  const handleCityInputBlur = () => {
    // Delay para garantir que o clique no item da lista seja registrado antes de ocultar
    setTimeout(() => setShowListCidade(false), 150);
  };

  async function criandoAlerta(e) {
    e.preventDefault();

    const data = {
      name: suggestionName && suggestionName,
      user_id: dadosSecond.id,
      contest_tag_name: suggestionContestValue ? suggestionContestValue : null,
      location_name: pesquisaCidade ? pesquisaCidade : null,
      office_tag_name: suggestionOfficeValue ? suggestionOfficeValue : null,
    };

    fetch(`${URL}/alerts/alerts/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          setRecarregar(!recarregar);
        }
        return res.json();
      })
      .catch((error) => console.error("Error:", error));

    setAlertOpen(false);
    setSuggestionName("");
    setSuggestionOfficeValue("");
    setSuggestionContestValue("");
    setPesquisaEstado("");
    setPesquisaCidade("");
    setSuggestionLocationValue("");
  }

  // filtros digitando nos inputs

  const filteredStates = estadosBrasil.filter((estado) =>
    estado.toLowerCase().includes(pesquisaEstado.toLowerCase())
  );
  const filteredCity = listaDeCidadesName.filter((cidade) =>
    cidade.toLowerCase().includes(pesquisaCidade.toLowerCase())
  );

  const filteredOffices = listaDeCargosName.filter((cargo) =>
    cargo.toLowerCase().includes(suggestionOfficeValue.toLowerCase())
  );

  const filteredContest = listaDeConcursosName.filter((concurso) =>
    concurso.toLowerCase().includes(suggestionContestValue.toLowerCase())
  );

  return (
    <StyledAlerta>
      <div className="flexAlerta">
        <div className="tittleAlerta">
          <h2>Alertas</h2>
          <p>
            Crie alertas para os <span>cargos, concursos e localidades</span>{" "}
            que você procura para ser{" "}
            <span>
              notificado por e-mail assim que o concurso estiver na Alô
              Concursos.
            </span>
          </p>
        </div>
        <div className="relativeBoxAlertaPopup">
          {/* substituir true por dadosSecond */}
          {dadosSecond ? (
            // substituir true por dadosSecond.subscription_active
            dadosSecond.subscription_active ? (
              <button
                onClick={() => setAlertOpen(true)}
                className="criarAlerta"
              >
                Criar alerta
              </button>
            ) : (
              <button
                // onMouseEnter={() => setPopUp(true)}
                className="criarAlerta disabledButton"
              >
                Criar alerta
              </button>
            )
          ) : null}

          {popUp && (
            <div className="popUp">
              <button onClick={() => setPopUp(false)} className="x">
                x
              </button>
              <Link to="/planos">Assine para criar alertas</Link>
            </div>
          )}
        </div>

        <h3 className="alertasCriadas">Alertas criados</h3>
        {alertOpen && (
          <form onSubmit={criandoAlerta} className="formAlerta">
            <h3>Criar alerta</h3>
            <p className="formAlertaText">
              Crie alertas para os <span>cargos, concursos e localidades</span>{" "}
              que você procura para ser{" "}
              <span>
                notificado por e-mail assim que o concurso estiver na Alô
                Concursos.
              </span>
            </p>
            <div className="boxLabelAlerta">
              <label htmlFor="name">Nome</label>
              <div className="boxLabelAlertaTextInput">
                <p>Adicionar nome do alerta</p>
                <input
                  value={suggestionName}
                  onChange={(e) => setSuggestionName(e.target.value)}
                  placeholder="Ex: Administração Municipal RJ"
                  type="text"
                  id="name"
                  autocomplete="off"
                />
              </div>
              <hr />
              <div className="boxLabelAlertaTextInput">
                <label htmlFor="cargos">Cargos</label>
                <p>Etiquetas de cargos</p>
                <div ref={refCargos}>
                  <input
                    value={suggestionOfficeValue}
                    onChange={(e) => setSuggestionOfficeValue(e.target.value)}
                    placeholder="Ex: Medicina"
                    type="text"
                    id="cargos"
                    onFocus={() => setShowListCargos(true)}
                    autocomplete="off"
                  />
                  {showListCargos && (
                    <ul
                      className="ulOption"
                      onClick={() => setShowListCargos(false)}
                    >
                      {filteredOffices.map((cargo) => (
                        <li
                          key={cargo}
                          onClick={() => setSuggestionOfficeValue(cargo)}
                        >
                          {cargo}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <hr />
              <label htmlFor="concurso">Concursos</label>
              <div className="boxLabelAlertaTextInput">
                <p>Etiquetas de concursos</p>
                <div ref={refConcursos}>
                  <input
                    onChange={(e) => setSuggestionContestValue(e.target.value)}
                    value={suggestionContestValue}
                    placeholder="Ex: Medicina"
                    type="text"
                    id="concurso"
                    onFocus={() => setShowListConcursos(true)}
                    autocomplete="off"
                  />
                  {showListConcursos && (
                    <ul
                      className="ulOption"
                      onClick={() => setShowListConcursos(false)}
                    >
                      {filteredContest.map((concurso) => (
                        <li
                          key={concurso}
                          onClick={() => setSuggestionContestValue(concurso)}
                        >
                          {concurso}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <hr />
              <label htmlFor="cidade">Estados</label>
              <div className="boxLabelAlertaTextInput">
                <p>Adicionar estado</p>
                <div ref={refEstados}>
                  <input
                    type="text"
                    id="search"
                    value={pesquisaEstado}
                    onChange={(e) => setPesquisaEstado(e.target.value)}
                    onFocus={() => setShowList(true)}
                    onBlur={handleInputBlur}
                    placeholder="Digite o nome do estado"
                    autocomplete="off"
                  />
                  {showList && (
                    <ul className="ulOption">
                      {filteredStates.map((estado) => (
                        <li
                          key={estado}
                          onClick={() => handleStateClick(estado)}
                        >
                          {estado}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <hr />
              <label htmlFor="cidade">Cidades</label>
              <div className="boxLabelAlertaTextInput">
                <p>Adicionar cidade</p>
                <div ref={refCidades}>
                  <input
                    value={pesquisaCidade}
                    onChange={(e) => setPesquisaCidade(e.target.value)}
                    onFocus={() => setShowListCidade(true)}
                    onBlur={handleCityInputBlur}
                    placeholder={
                      pesquisaEstado
                        ? `Ex: Niterói - RJ`
                        : `Por favor preencha o estado acima`
                    }
                    type="text"
                    id="cidade"
                    disabled={!pesquisaEstado}
                    autocomplete="off"
                  />
                  {showListCidade && (
                    <ul className="ulOption">
                      {filteredCity.map((cidade, index) => (
                        <li key={index} onClick={() => handleCityClick(cidade)}>
                          {cidade}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              {/* <div className="formAlertFlexSuggestions">
                <div>
                  <div
                    onClick={() => setSuggestionLocationValue("São Paulo - SP")}
                    className="suggestion"
                  >
                    <p>São Paulo - SP</p>
                  </div>
                  <div
                    onClick={() => setSuggestionLocationValue("Ubá - MG")}
                    className="suggestion"
                  >
                    <p>Ubá - MG</p>
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => setSuggestionLocationValue("Picos - PI")}
                    className="suggestion"
                  >
                    <p>Picos - PI</p>
                  </div>
                  <div
                    onClick={() => setSuggestionLocationValue("Dourados - MS")}
                    className="suggestion"
                  >
                    <p>Dourados - MS</p>
                  </div>
                </div>
              </div> */}
              <hr />
              {/* <label htmlFor="concurso">Resultados</label>
              <div className="boxLabelAlertaTextInput">
                <p>Cargos</p>
                <span>2.202 cargos encontrados</span>
                <div className="slidePlaceholder">
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className="boxLabelAlertaTextInput">
                <p>Concursos</p>
                <span>202 concursos encontrados</span>
                <div className="slidePlaceholder">
                  <div></div>
                  <div></div>
                </div>
              </div>
              <hr /> */}
              <div className="formAlertButtons">
                <button type="submit" className="salvar">
                  <img src={disk} alt="disquete" />
                  <p>Salvar</p>
                </button>
                <button onClick={() => setAlertOpen(false)}>
                  <img src={cross} alt="xis" />
                  <p>Fechar</p>
                </button>
              </div>
            </div>
          </form>
        )}

        {dadosSecond &&
          dadosSecond.alerts.map((alert, index) => (
            <AlertCard
              setRecarregar={setRecarregar}
              recarregar={recarregar}
              key={index}
              alert={alert}
            />
          ))}
      </div>
    </StyledAlerta>
  );
}

export default Alerta;
