import styled from "styled-components";

export const StyledFilter = styled.aside`
  .filterHide {
    display: none;
  }
  .filterClean {
    margin: 1rem 0 0.5rem;
    background-color: #fff;
    padding: 0.4rem 1rem;
    color: var(--Primary-500);
    font-family: "Work Sans", sans-serif;
    border-radius: 0.4rem;
    border-width: 0.1rem;
    border-color: var(--Primary-500);
    border-style: solid;
    font-size: 1.6rem;
    cursor: pointer;
  }
  .flex {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 2rem;
    color: #474747;
    font-family: "Work Sans", sans-serif;

    img {
      width: 1.5rem;
    }

    h3 {
      font-weight: normal;
    }
  }

  .vacancyRanged {
    grid-column: 1/3;
    grid-row: 3/4;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-top: 1.5rem;

    .tittle {
      font-weight: bold;
      font-size: 1.6rem;
      font-family: "Work Sans", sans-serif;
      color: var(--Grey-500);
    }
    .rangegChoose {
      font-family: "Work Sans", sans-serif;
      color: var(--Grey-500);
      font-size: 1.6rem;
    }
    .slider {
      margin-top: 1.6rem;
      width: 100%;
      height: 2px;
      background-color: var(--Grey-500);

      .thumb {
        width: 12px;
        height: 12px;
        cursor: grab;
        background-color: #252a3d;
        border-radius: 50%;
        /* border: 2px solid var(--Grey-900); */
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .wageRanged {
    grid-column: 1/3;
    grid-row: 4/5;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-top: 1.5rem;

    .tittle {
      font-weight: bold;
      font-size: 1.6rem;
      font-family: "Work Sans", sans-serif;
      color: var(--Grey-500);
    }
    .rangegChoose {
      font-family: "Work Sans", sans-serif;
      color: var(--Grey-500);
      font-size: 1.6rem;
    }
    .slider {
      margin-top: 1.6rem;
      width: 100%;
      height: 2px;
      background-color: var(--Grey-500);

      .thumb {
        width: 12px;
        height: 12px;
        cursor: grab;
        background-color: #252a3d;
        border-radius: 50%;
        /* border: 2px solid var(--Grey-900); */
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  @media (max-width: 969px) {
    width: 100%;
    .gridFilterMobile {
      position: relative;
      display: grid;
      grid-template: repeat(4, 1fr) / 1fr 1fr;
      column-gap: 3.6rem;
      row-gap: 2.4rem;
    }
  }

  @media (max-width: 470px) {
    .EtapasFilter {
      grid-column: 1/3;
    }
    .wageRanged {
      grid-row: 4/5;
    }
  }
`;
