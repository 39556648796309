import React from "react";
import { StyledSelectInput } from "../styles/SelectInput";
import { useState } from "react";
import briefCase from "../assets/briefcaseOffice.svg";
import governmentFlag from "../assets/governmentFlag.svg";

function SelectInput({ defaultValue = "Concursos", setSelected }) {
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [ulVisible, setUlVisible] = useState(false);

  function cargos() {
    setSelectedOption("Cargos");
    setSelected("Cargos");
    setUlVisible(false);
  }
  function concursos() {
    setSelectedOption("Concursos");
    setSelected("Concursos");
    setUlVisible(false);
  }

  function cidades() {
    setSelectedOption("Cidades");
    setSelected("Cidades");
    setUlVisible(false);
  }

  return (
    <StyledSelectInput>
      <button
        type="button"
        className="selectedInput"
        onClick={() => setUlVisible((prev) => !prev)}
      >
        {selectedOption === "Cargos" ? (
          <img
            style={{ width: "16px", height: "16px" }}
            src={briefCase}
            alt="Cargos"
          />
        ) : selectedOption === "Concursos" ? (
          <img
            style={{ width: "16px", height: "16px" }}
            src={governmentFlag}
            alt="Concursos"
          />
        ) : (
          selectedOption === "Cidades" && (
            <img
              style={{ width: "16px", height: "16px" }}
              src={governmentFlag}
              alt="Cidades"
            />
          )
        )}
        <p>{selectedOption}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
        >
          <path
            d="M5.23687 6H12.6902C12.8221 6.00055 12.9508 6.04019 13.0601 6.1139C13.1694 6.18761 13.2544 6.29208 13.3044 6.4141C13.3543 6.53612 13.367 6.67021 13.3407 6.79942C13.3145 6.92863 13.2505 7.04715 13.1569 7.14L9.43687 10.86C9.37489 10.9225 9.30116 10.9721 9.21992 11.0059C9.13868 11.0398 9.05154 11.0572 8.96354 11.0572C8.87553 11.0572 8.78839 11.0398 8.70715 11.0059C8.62591 10.9721 8.55218 10.9225 8.4902 10.86L4.7702 7.14C4.67659 7.04715 4.61261 6.92863 4.58635 6.79942C4.56009 6.67021 4.57274 6.53612 4.62269 6.4141C4.67264 6.29208 4.75765 6.18761 4.86698 6.1139C4.9763 6.04019 5.10502 6.00055 5.23687 6Z"
            fill="#33394D"
          />
        </svg>
      </button>
      {ulVisible ? (
        <ul>
          {selectedOption === "Cargos" ? (
            <>
              <li onClick={() => concursos()}>
                <button>
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={governmentFlag}
                    alt="Concursos"
                  />

                  <p className="option">Concursos</p>
                </button>
              </li>

              <li onClick={() => cidades()}>
                <button>
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={governmentFlag}
                    alt="Cidades"
                  />

                  <p className="option">Cidades</p>
                </button>
              </li>
            </>
          ) : selectedOption === "Concursos" ? (
            <>
              <li onClick={() => cargos()}>
                <button>
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={briefCase}
                    alt="Cargos"
                  />
                  <p className="option">Cargos</p>
                </button>
              </li>

              <li onClick={() => cidades()}>
                <button>
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={governmentFlag}
                    alt="Cidades"
                  />

                  <p className="option">Cidades</p>
                </button>
              </li>
            </>
          ) : (
            selectedOption === "Cidades" && (
              <>
                <li onClick={() => cargos()}>
                  <button>
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src={briefCase}
                      alt="Cargos"
                    />
                    <p className="option">Cargos</p>
                  </button>
                </li>
                <li onClick={() => concursos()}>
                  <button>
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src={governmentFlag}
                      alt="Concursos"
                    />

                    <p className="option">Concursos</p>
                  </button>
                </li>
              </>
            )
          )}
        </ul>
      ) : null}
    </StyledSelectInput>
  );
}

export default SelectInput;
