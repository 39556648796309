import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel } from "swiper/modules";
import Card from "../components/Card";

function SlideInnerCard({ renderizacao }) {
  return (
    <Swiper
      modules={[Navigation, Mousewheel]}
      initialSlide={1}
      navigation={true}
      slidesPerView={3}
      spaceBetween={10}
      breakpoints={{
        1390: {
          slidesPerView: 3,
        },
        1276: {
          slidesPerView: 2,
          spaceBetween: 250,
        },
        1257: {
          slidesPerView: 2,
          spaceBetween: 150,
        },
        1164: {
          slidesPerView: 2,
          spaceBetween: 100,
        },
        1106: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1056: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        939: {
          slidesPerView: 1,
          spaceBetween: 150,
        },
        415: {
          slidesPerView: 1,
          spaceBetween: 150,
        },
        0: {
          slidesPerView: 1,
          spaceBetween: 50,
        },
      }}
    >
      {renderizacao &&
        renderizacao.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Card
                cssAdd={"cardInnerCard"}
                display_name={item.name}
                diaDaProva={item.exam_date && item.exam_date}
                local={
                  item.location_work_override_detail
                    ? item.location_work_override_detail.name
                      ? item.location_work_override_detail.name
                      : item.location_work
                    : item.location_work
                }
                taxa={item.registration_fee}
                icone={
                  "https://icones-aloconcursos.s3.us-east-1.amazonaws.com/suitcase.svg"
                }
                tagsmy={item.tags && item.tags}
                banca={item.organization}
                {...item.contest}
                {...item}
                newclassname="cardInnerCard"
              />
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
}

export default SlideInnerCard;
