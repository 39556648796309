import styled from "styled-components";

export const StyledBlogHomepage = styled.main`
  min-height: calc(100vh - var(--NewFooterHeight));
  padding-top: 0;
  .firstSection {
    max-width: 1664px;
    margin: 0 auto;
    padding: var(--Scale-6, 48px) var(--Scale-0, 0px);
    .boxTittle {
      display: flex;
      padding: var(--Scale-12, 96px) var(--Scale-0, 0px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--Scale-3, 24px);

      h1 {
        color: var(--Grey-700, #33394d);
        text-align: center;
        font-family: "Work Sans";
        font-size: 96px;
        font-style: normal;
        font-weight: 400;
        line-height: 90%;
        letter-spacing: -1.92px;
      }
      p {
        color: var(--Grey-600, #495066);
        text-align: center;

        /* Title/Medium/Regular */
        font-family: "Work Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .mainPost {
      display: flex;
      padding: var(--Scale-3, 24px) var(--Scale-0, 0px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Scale-3, 24px);
      img {
        border-radius: 8px;
        /* height: 800px; */
        max-width: 100%;
      }

      .tittleAndText {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        h2 {
          color: var(--Grey-700, #33394d);

          /* Display/Small/Bold */
          font-family: "Work Sans";
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 110%; /* 52.8px */
          letter-spacing: -0.96px;
        }
        p {
          overflow: hidden;
          color: var(--Grey-600, #495066);
          text-overflow: ellipsis;

          /* Text/Extra Large/Regular */
          font-family: "Work Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 25.2px */
          letter-spacing: 0.18px;
        }
      }
    }
    .posts {
      display: flex;
      padding: 32px var(--Scale-0, 0px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Scale-3, 24px);
      h2 {
        color: var(--Grey-700, #33394d);

        /* Title/Large/Bold */
        font-family: "Work Sans";
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 36px */
      }
      .gridCards {
        width: 100%;
        display: grid;
        row-gap: 24px;
        column-gap: 24px;
        grid-template-columns: repeat(auto-fill, minmax(398px, 1fr));
      }
    }
    .loadMore {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      button {
        display: flex;
        height: var(--Scale-6, 48px);
        padding: 12px var(--Scale-2, 16px);
        align-items: center;
        justify-content: center;
        gap: var(--Scale-2, 16px);
        background-color: transparent;
        border: none;
        p {
          color: var(--Primary-500, #1b2cc1);
          text-align: center;
          font-family: "Inter", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 20.8px */
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: none;
          text-decoration-thickness: auto;
          text-underline-offset: auto;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 1700px) {
    .firstSection {
      max-width: 1284px;
      padding: 0 0 48px 0;
    }
  }
  @media (max-width: 1300px) {
    .firstSection {
      max-width: 95%;
      .boxTittle {
        padding: 48px var(--Scale-0, 0px);
      }
      .posts {
        .gridCards {
          width: 846px;
          grid-template-columns: repeat(auto-fill, minmax(411px, 1fr));
        }
      }
    }
  }

  @media (max-width: 870px) {
    .firstSection {
      .boxTittle {
        h1 {
          font-size: 48px;
        }
      }
    }
    .posts {
      .gridCards {
        width: 100% !important;
        grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
      }
    }
  }
`;
