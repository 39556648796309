import styled from "styled-components";

export const StyledSearchResults = styled.main`
  margin: 2rem auto 0 auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .CardMenu {
    margin-left: 1.067rem;
    .tituloPrincipal {
      width: fit-content;
      color: var(--Grey-500);
      font-family: "Work Sans", sans-serif;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -1.44px;
    }
    .cardsGrid {
      padding: 3.2rem 1.0667rem 0rem 1.0667rem;
      display: grid;
      grid-template: repeat(2, 57.9rem) / repeat(auto-fill, minmax(32rem, 1fr));
      row-gap: 2rem;
      column-gap: 2rem;
    }
    .cardConcursoGrid {
      display: grid;
      padding: 3.2rem 1.0667rem 0rem 1.0667rem;
      grid-template: repeat(2, 50.3125rem) / repeat(
          auto-fill,
          minmax(32rem, 1fr)
        );
      row-gap: 2rem;
      column-gap: 2rem;
    }
  }

  @media (max-width: 969px) {
    .SectionCard {
      grid-template: 1fr auto 45px / 100% !important;
      gap: 3rem;

      .CardMenu {
        grid-column: 1/2;
      }
    }
  }
  @media (max-width: 485px) {
    .CardMenu {
      .cardsGrid {
        grid-template: repeat(2, 57.9rem) / repeat(
            auto-fill,
            minmax(28rem, 1fr)
          );
      }
      .cardConcursoGrid {
        grid-template: repeat(2, 50.3125rem) / repeat(
            auto-fill,
            minmax(28rem, 1fr)
          );
      }
    }
  }
  .flexTituloAlerta {
    display: flex;
    gap: 3.2rem;
    align-items: center;

    .tagButton {
      text-decoration: none;
      padding: 1.2rem 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.6rem;
      border: none;
      background: var(--Primary-500, #1b2cc1);
      color: var(--Primary-050, #f0fdf4);
      font-family: "Work Sans";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.8rem;
      cursor: pointer;
    }
  }
`;
