import styled from "styled-components";

export const StyledAlerta = styled.div`
  padding: 32px 0;

  .flexAlerta {
    display: flex;
    flex-direction: column;
    gap: 2.7rem;
    /* padding: 3.2rem 1.6rem; */

    .tittleAlerta {
      display: flex;
      flex-direction: column;
      h2 {
        color: var(--Grey-700, #33394d);
        font-family: "Work Sans";
        font-size: 3.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.72px;
      }
      p {
        color: var(--Grey-500, #606780);

        /* Text/Medium/Regular */
        font-family: "Work Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */

        span {
          color: var(--Grey-500, #606780);

          /* Text/Medium/Regular */
          font-family: "Work Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }
      }
    }

    .relativeBoxAlertaPopup {
      display: flex;
      align-items: center;
      gap: 4rem;

      .popUp {
        position: relative;
        background-color: rgba(27, 44, 193, 0.5);
        border-radius: 8px;
        color: #fff;
        padding: 1.6rem 2rem;
        font-size: 1.6rem;

        .x {
          position: absolute;
          left: 100%;
          top: -0.5rem;
          transform: translateX(-90%);
          width: 2.5rem;
          height: 2.5rem;
          background-color: white;
          color: red;
          cursor: pointer;
        }

        a {
          color: #fff;
          text-decoration: none;
          &:visited {
            color: #fff;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .criarAlerta {
      width: fit-content;
      display: flex;
      height: 4.8rem;
      padding: 1.2rem 2.4rem;
      align-items: center;
      gap: var(--Scale-2, 16px);
      border-radius: var(--Radius-md, 8px);
      background: var(--Primary-500, #1b2cc1);
      color: #fff;
      text-align: center;

      /* Text/Large/Regular */
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.16px;
      cursor: pointer;
    }
    .disabledButton {
      background-color: var(--Grey-300);
      opacity: 0.65;
      cursor: not-allowed;
    }
    .alertasCriadas {
      color: var(--Grey-700, #33394d);

      /* Title/Medium/Regular */
      font-family: "Work Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .formAlerta {
      overflow: hidden;
      display: flex;
      width: 328px;
      padding: 40px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      border-radius: 8px;
      background: var(--Supporting-white, #fff);

      /* Drop shadow/xs */
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);

      h3 {
        color: var(--Grey-700, #33394d);

        /* Title/Medium/Regular */
        font-family: "Work Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .formAlertaText {
        color: var(--Grey-500, #606780);

        /* Text/Medium/Regular */
        font-family: "Work Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        span {
          color: var(--Grey-500, #606780);

          /* Text/Medium/Regular */
          font-family: "Work Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }
      }
      .boxLabelAlerta {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.6rem;

        label {
          color: var(--Grey-500, #606780);

          /* Title/Small/Regular */
          font-family: "Work Sans";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 28px */
        }
        .boxLabelAlertaTextInput {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 0.8rem;

          p {
            color: var(--Grey-500, #606780);

            /* Text/Medium/Bold */
            font-family: "Work Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 21px */
          }
          span {
            color: var(--Grey-500, #606780);

            /* Text/Medium/Regular */
            font-family: "Work Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
          }
          input {
            width: 100%;
            display: flex;
            padding: 12px 16px;
            align-items: center;
            gap: 16px;
            align-self: stretch;
            border-radius: 4px;
            border: 1px solid var(--Primary-500, #1b2cc1);
            background: var(--Grey-50, #f7f8ff);
          }
        }
        hr {
          width: 100%;
          /* height: 1px; */
          /* background: #d9d9d9; */
        }
      }
      .formAlertFlexSuggestions {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        div {
          width: 100%;
          display: flex;
          gap: 1.6rem;

          .suggestion {
            cursor: pointer;
            width: fit-content;
            display: flex;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 12px;
            border-radius: 4px;
            border: 1px solid var(--Grey-500, #606780);

            p {
              color: var(--Grey-500, #606780);

              /* Text/Medium/Regular */
              font-family: "Work Sans";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 21px */
            }
          }
        }
      }
      .formAlertButtons {
        display: flex;
        gap: 24px;
        button {
          cursor: pointer;
          display: flex;
          padding: 12px 24px;
          align-items: center;
          gap: var(--Scale-2, 16px);
          border-radius: var(--Radius-md, 8px);
          border: 1px solid var(--Primary-500, #1b2cc1);
          background-color: transparent;
          p {
            color: var(--Primary-500, #1b2cc1);
            text-align: center;

            /* Text/Large/Regular */
            font-family: "Work Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.16px;
          }
        }
      }
      .salvar {
        background: var(--Primary-500, #1b2cc1) !important;
        p {
          color: #fff !important;
        }
      }
    }
    .slidePlaceholder {
      display: flex;
      gap: 8px;
      width: 1000px;

      div {
        width: 252px;
        height: 437.235px;

        background-color: grey;
      }
    }

    .cardAlertComplete {
      /* transition: all 0.5s; */
      max-width: 100rem;
      display: flex;
      flex-direction: column;
      gap: 16px;
      background: var(--Supporting-white, #fff);
      padding: 12px 16px;
      /* padding: 64px 32px; */
      h2 {
        color: var(--Grey-700, #33394d);
        font-family: "Work Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      h3 {
        color: var(--Grey-500, #606780);
        font-family: "Work Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
      p {
        color: var(--Grey-500, #606780);

        /* Text/Medium/Regular */
        font-family: "Work Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }

      span {
        color: var(--Grey-500, #606780);
        font-family: "Work Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .filterOpenPart {
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
      }
      .filterAlertDone {
        display: flex;

        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        .trash {
          cursor: pointer;
          background-color: transparent;
          border: none;
          img {
            width: 2.4rem;
          }
        }
        .angleDown {
          cursor: pointer;
          background-color: transparent;
          border: none;
          transform: rotate(90deg);
        }
        div {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;

          h4 {
            color: var(--Grey-600, #495066);
            font-family: "Work Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 21px */
            &::first-letter {
              text-transform: capitalize;
            }
          }
          p {
            color: var(--Grey-500, #606780);

            /* Text/Small/Regular */
            font-family: "Work Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }

  .cardInnerCard {
    width: 25rem !important;
    min-height: 48rem !important;
    max-height: 48rem !important;

    .tagNormal {
      p {
        color: #fff;
      }
    }
    .CardStickyC {
      h2 {
        color: var(--Grey-700);
        text-align: center;
        font-family: "Work Sans", sans-serif;
        font-size: 3.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2058rem;
        height: 4.5rem;
      }
    }
  }

  .slideContainerInnerCard {
    width: 100%;
    position: relative;
    margin-bottom: 8rem;
    overflow-x: hidden !important;
    overflow-y: visible !important;
    /* overflow: hidden; */
    .swiper-button-prev {
      top: 0 !important;
      margin-top: 0 !important;
      height: 100% !important;
      left: 0 !important;
      background-color: white !important;
      width: 10rem !important;
      z-index: 10 !important;
      border: none;
    }
    .swiper-button-next {
      top: 0 !important;
      margin-top: 0 !important;
      height: 100% !important;
      right: 0rem !important;
      width: 10rem !important;
      background-color: white !important;
      z-index: 10 !important;
    }
    .swiper {
      padding: 0 10rem !important;
    }
    .swiper-wrapper {
      justify-content: flex-start !important;
      width: 100%;
      margin: 0;
      padding: 2rem;
    }
  }

  .ulOption {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    li {
      padding: 8px;
      cursor: pointer;
      border-bottom: 1px solid #ddd;
      font-size: 14px;
      font-family: Work Sans;
    }
  }

  .buttonsEditTrash {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    button {
      cursor: pointer;
      width: 14rem;
      display: flex;
      padding: 12px 24px;
      align-items: center;
      gap: var(--Scale-2, 16px);
      background-color: transparent;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .edit {
      border-radius: var(--Radius-md, 8px);
      border: 1px solid var(--Primary-500, #1b2cc1);
      p {
        color: var(--Primary-500, #1b2cc1);
        text-align: center;

        /* Text/Large/Regular */
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
      }
    }
    .trash {
      border-radius: var(--Radius-md, 8px);
      border: 1px solid var(--Error-600, #ba1a22);

      p {
        color: var(--Error-600, #ba1a22);
        text-align: center;

        /* Text/Large/Regular */
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
      }
      .concluir {
        border-radius: var(--Radius-md, 8px);
        border: 1px solid rgb(11, 116, 7);
        p {
          color: #fff;
          text-align: center;

          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
        }
      }
    }
  }
`;
