import React from "react";
import { StyledFlexMenu } from "../styles/FlexMenu";
import { logout } from "../actions/auth";
import { connect } from "react-redux";
// import lock from "../assets/lock.svg";
import padlock2 from "../assets/padlock2.png";

function FlexMenu({
  setPage,
  page,
  icon,
  active,
  colorRed,
  logout,
  name,
  locked,
}) {
  return (
    <>
      <StyledFlexMenu className="flexMenu">
        {icon ? <img src={icon} alt={page} /> : null}
        <button
          className={active ? "active" : "inactive"}
          onClick={() => (page === "Sair da Conta" ? logout() : setPage(page))}
          style={colorRed ? { color: "#A11722" } : null}
        >
          {name}
        </button>
        {page === "alerta" ? (
          locked ? null : (
            <img src={padlock2} alt="cadeado" />
          )
        ) : null}
      </StyledFlexMenu>
    </>
  );
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(FlexMenu);
