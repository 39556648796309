import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
// ! DEFAULT RESET
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

main{
  overflow-x: hidden;
  padding-top: 10.4rem;
  margin: 0 auto !important;

}

// VARIABLES
:root{
--NavHeight: 8rem;
--grey-900: #252A3D;
--FooterHeight: 7.9rem;
--NewFooterHeight: 70.41px;
--MarginTopPadrao: 9.6rem;
--MaginBottomPadrao: 9.6rem;

color-scheme: light;




/* .container {
  width: 90%;
  margin: 0 auto;
} */

// GRID DE OFFICE E DE CONTESTS
.SectionCard{
  display: grid;
  row-gap: 4.8rem;
  column-gap: 3.2rem;
  margin-bottom: 6.4rem;
  grid-template: 1fr / 23.2rem 1fr;
}

/* STATUS */
.inscricoesAbertas {
    background-color: #e8fcf0;
    color: #177334;
  }
  .inscricoesEncerradas {
    background-color: #fff3f2;
    color: #ba1a22;
  }
  .editalPublicado {
    background-color: #feffeb;
    color: #bd791b;
  }
  .tagNormal {
    background-color: #7578e0;
    color: white;
    cursor: pointer;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 996; 
  }

// ? ESCALAS
--Scale-025: 0.2rem;
--Scale-05: 0.4rem;
--Scale-1: 0.8rem;
--Scale-2: 1.6rem;
--Scale-3: 2.4rem;
--Scale-4: 3.2rem;
--Scale-5: 4rem;
--Scale-6: 4.8rem;
--Scale-7: 5.6rem;
--Scale-8: 6.4rem;
--Scale-9: 7.2rem;
--Scale-10: 8rem;
--Scale-11: 8.8rem;
--Scale-12: 9.6rem;
--Scale-13: 10.4rem;
--Scale-14: 11.2rem;
--Scale-15: 12rem;
--Scale-16: 12.8rem;
--Scale-17: 13.6rem;
--Scale-18: 14.4rem;
--Scale-19: 15.2rem;
--Scale-20: 16rem;

//TODO RADIUS
--Radius-sm: 0.4rem;
--Radius-md: 0.8rem;
--Radius-lg: 1.6rem;
--Radius-xl: 3.2rem;
--Radius-2xl: 12.8rem;
--Radius-3xl: 36rem;

/* CORES */
/* PRIMARY */
--Primary-900: #02175C;
--Primary-800: #071D75;
--Primary-700: #11268F;
--Primary-600: #1629A8;
--Primary-500: #1B2CC1;
--Primary-400: #454ED1; 
--Primary-300: #7578E0;
--Primary-200: #AAAAF0;
--Primary-100: #E6E5FF;
--Primary-50: #F2F3FF;
--Primary-25: #F2F3FF;

/* SUCESS */
--Sucess-25: #F2FFF7;
--Sucess-50: #E8FCF0;
--Sucess-100: #C6F7DA;
--Sucess-200: #94E3B3;
--Sucess-300: #66CC88;
--Sucess-400: #3FB563;
--Sucess-500: #21A645;
--Sucess-600: #1C8C3C;
--Sucess-700: #177334;
--Sucess-800: #12592B;
--Sucess-900: #0D401F;

/* GREY */
--Grey-25: #FCFDFF;
--Grey-50: #F7F8FF;
--Grey-100: #EDEEFA;
--Grey-200: #C5C8DB;
--Grey-300: #A0A5BD;
--Grey-400: #7E849E;
--Grey-500: #606780;
--Grey-600: #495066;
--Grey-700: #33394D;
--Grey-800: #2A2E40;
--Grey-900: #252A3D;

/* ERROR */
--Error-25: #FFFAFA;
--Error-50: #FFF3F2;
--Error-100: #FCE6E3;
--Error-200: #F2B2AC;
--Error-300: #E87E79;
--Error-400: #DE4C49;
--Error-500: #D41E21;
--Error-600: #BA1A22;
--Error-700: #A11722;
--Error-800: #871321;
--Error-900: #6E0B1A;

/* WARNING */
--Warning-25: #FFFFF2;
--Warning-50: #FEFFEB;
--Warning-100: #FDFFC9;
--Warning-200: #FFFA99;
--Warning-300: #FCEE68;
--Warning-400: #FAE037;
--Warning-500: #F7C307;
--Warning-600: #DEA51F;
--Warning-700: #BD791B;
--Warning-800: #9C5216;
--Warning-900: #7A3411;
}

a:active, a:focus {
    text-decoration: none;
    color: inherit; 
}

@media (max-width: 1300px) {
    .gridItem5 {
      grid-column: 2/4;
    }
  }
@media (max-width: 823px) {
      .gridItem5 {
      grid-column: 1/2;
    }
  }

`;

export default GlobalStyles;
