import { useState, useEffect } from "react";
import CardConcurso from "../components/CardConcurso";
import Filter from "../components/Filter";
import Pagination from "../components/Pagination";
import queryString from "query-string";
import { StyledConcursos } from "../styles/Concursos";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import SecondSearchBar from "../components/SecondSearchBar";
import { estados, faseConcurso, vagas, salario } from "../tools/filters";
import Skeleton from "../UI/Skeleton";
import FilterHide from "../UI/FilterHide";
import { Link } from "react-router-dom";

ReactGA.initialize("G-9EJ6PS0PBE");

const MIN = 0;
const MAX = 50000;

const MINVacancy = 0;
const MAXVacancy = 1000;

function Concursos({
  state,
  setState,
  stage,
  setStage,
  education,
  setEducation,
  vacancy,
  setVacancy,
  wage,
  setWage,
  recarregar,
  setTermoPesquisado,
  setUrlAtual,
  setSearchReset,
  termoPesquisado,
  searchReset,
  setOverlay,
  overlay,
}) {
  document.title = "Concursos - Alô Concursos";
  const [skeleton, setSkeleton] = useState(false);
  const [open, setOpen] = useState(false);
  const [wageValue, setWageValue] = useState([MIN, MAX]);
  const [vacancyValue, setVacancyValue] = useState([MINVacancy, MAXVacancy]);
  // Variavéis que vem de app todas desestruturadas
  const {
    nacional,
    saoPaulo,
    rioDeJaneiro,
    distritoFederal,
    acre,
    alagoas,
    amapa,
    amazonas,
    bahia,
    ceara,
    espiritoSanto,
    goias,
    maranhao,
    matoGrosso,
    matoGrossoDoSul,
    minasGerais,
    para,
    paraiba,
    parana,
    pernambuco,
    piaui,
    rioGrandeDoNorte,
    rioGrandeDoSul,
    rondonia,
    roraima,
    santaCatarina,
    sergipe,
    tocantins,
  } = state;
  const {
    setNacional,
    setSaoPaulo,
    setRioDeJaneiro,
    setDistritoFederal,
    setAcre,
    setAlagoas,
    setAmapa,
    setAmazonas,
    setBahia,
    setCeara,
    setEspiritoSanto,
    setGoias,
    setMaranhao,
    setMatoGrosso,
    setMatoGrossoDoSul,
    setMinasGerais,
    setPara,
    setParaiba,
    setParana,
    setPernambuco,
    setPiaui,
    setRioGrandeDoNorte,
    setRioGrandeDoSul,
    setRondonia,
    setRoraima,
    setSantaCatarina,
    setSergipe,
    setTocantins,
  } = setState;
  const { preInscricao, inscricoesAbertas, inscricoesEncerradas } = stage;
  const { setPreInscricao, setInscricoesAbertas, setInscricoesEncerradas } =
    setStage;
  const { superior, fundamental, medio, mestrado, doutorado } = education;
  const { setSuperior, setFundamental, setMedio, setMestrado, setDoutorado } =
    setEducation;
  const {
    cadastroReserva,
    vagaUm,
    vagaDoisDez,
    vagaDez,
    vagaVinteCinco,
    vagaCinquenta,
    vagaCem,
  } = vacancy;
  const {
    setCadastroReserva,
    setVagaUm,
    setVagaDoisDez,
    setVagaDez,
    setVagaVinteCinco,
    setVagaCinquenta,
    setVagaCem,
  } = setVacancy;
  const {
    semRendimentos,
    salario1,
    salario2,
    salario3,
    salario4,
    salario5,
    salario6,
    salario7,
    salario8,
    salario9,
    salario10,
    salario11,
    salario12,
    salario13,
    salario14,
  } = wage;
  const {
    setSemRendimentos,
    setSalario1,
    setSalario2,
    setSalario3,
    setSalario4,
    setSalario5,
    setSalario6,
    setSalario7,
    setSalario8,
    setSalario9,
    setSalario10,
    setSalario11,
    setSalario12,
    setSalario13,
    setSalario14,
  } = setWage;

  // Variáveis para consumo da API
  const Token = process.env.REACT_APP_TOKEN;
  const URL = process.env.REACT_APP_API_URL;

  // Captura de URL atual
  const location = useLocation();
  // Lógica pra capturar a página antes do login
  useEffect(() => {
    setTimeout(() => {
      setSkeleton(true);
    }, 1000);
    setUrlAtual(location.pathname);
  }, []);
  // Permite navegar para uma página de forma programática
  const navigate = useNavigate();
  // Utiliza a biblioteca queryString para analisar a query da URL atual e armazenar os parâmetros como um objeto JavaScript.
  const queryParams = queryString.parse(location.search);

  // Variáveis para o controle de paginação e quantidade de itens na página
  const [currentPage, setCurrentPage] = useState(1);
  const [cardLimitPerPage, setCardLimitPerPage] = useState(8);
  const [offset, setOffset] = useState(
    queryParams.offset ? parseInt(queryParams.offset) : 0
  ); // Aqui define offset pra 0 se não tiver nenhum na URL, se tiver um na URL ele define pra aquele

  // Estou usando esta variável para segurar o que vou renderizar na tela
  const [renderizacao, setRenderizacao] = useState(null);
  // Variável para o texto que aparece antes da contagem
  const [cargoText, setCargoText] = useState("cargos");
  const [foundText, setFoundText] = useState("encontrados");
  // Variável para contador de itens filtrados ou pesquisados
  const [count, setCount] = useState(null);
  // Primera vez que renderiza
  const [firstRender, setFirstRender] = useState(false);
  // Resetando as variáveis
  const [clean, setClean] = useState(false);

  useEffect(() => {
    setOffset(0);
  }, [recarregar]);

  useEffect(() => {
    async function resetFilters() {
      setNacional(false);
      setSaoPaulo(false);
      setRioDeJaneiro(false);
      setDistritoFederal(false);
      setAcre(false);
      setAlagoas(false);
      setAmapa(false);
      setAmazonas(false);
      setBahia(false);
      setCeara(false);
      setEspiritoSanto(false);
      setGoias(false);
      setMaranhao(false);
      setMatoGrosso(false);
      setMatoGrossoDoSul(false);
      setMinasGerais(false);
      setPara(false);
      setParaiba(false);
      setParana(false);
      setPernambuco(false);
      setPiaui(false);
      setRioGrandeDoNorte(false);
      setRioGrandeDoSul(false);
      setRondonia(false);
      setRoraima(false);
      setSantaCatarina(false);
      setSergipe(false);
      setTocantins(false);
      setPreInscricao(false);
      setInscricoesAbertas(false);
      setInscricoesEncerradas(false);
      setSuperior(false);
      setFundamental(false);
      setMedio(false);
      setMestrado(false);
      setDoutorado(false);
      setCadastroReserva(false);
      setVagaUm(false);
      setVagaDoisDez(false);
      setVagaDez(false);
      setVagaVinteCinco(false);
      setVagaCinquenta(false);
      setVagaCem(false);
      setSemRendimentos(false);
      setSalario1(false);
      setSalario2(false);
      setSalario3(false);
      setSalario4(false);
      setSalario5(false);
      setSalario6(false);
      setSalario7(false);
      setSalario8(false);
      setSalario9(false);
      setSalario10(false);
      setSalario11(false);
      setSalario12(false);
      setSalario13(false);
      setSalario14(false);
    }

    resetFilters().then(() => {
      setClean(true);
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1817 && window.innerWidth > 1439) {
        setCardLimitPerPage(6);
      } else if (window.innerWidth <= 1439) {
        setCardLimitPerPage(4);
      } else {
        setCardLimitPerPage(8);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  async function changingVariables() {
    if (JSON.stringify(queryParams) === "{}") {
      setNacional(false);
      setSaoPaulo(false);
      setRioDeJaneiro(false);
      setDistritoFederal(false);
      setAcre(false);
      setAlagoas(false);
      setAmapa(false);
      setAmazonas(false);
      setBahia(false);
      setCeara(false);
      setEspiritoSanto(false);
      setGoias(false);
      setMaranhao(false);
      setMatoGrosso(false);
      setMatoGrossoDoSul(false);
      setMinasGerais(false);
      setPara(false);
      setParaiba(false);
      setParana(false);
      setPernambuco(false);
      setPiaui(false);
      setRioGrandeDoNorte(false);
      setRioGrandeDoSul(false);
      setRondonia(false);
      setRoraima(false);
      setSantaCatarina(false);
      setSergipe(false);
      setTocantins(false);
      setPreInscricao(false);
      setInscricoesAbertas(false);
      setInscricoesEncerradas(false);
      setSuperior(false);
      setFundamental(false);
      setMedio(false);
      setMestrado(false);
      setDoutorado(false);
      setCadastroReserva(false);
      setVagaUm(false);
      setVagaDoisDez(false);
      setVagaDez(false);
      setVagaVinteCinco(false);
      setVagaCinquenta(false);
      setVagaCem(false);
      setSemRendimentos(false);
      setSalario1(false);
      setSalario2(false);
      setSalario3(false);
      setSalario4(false);
      setSalario5(false);
      setSalario6(false);
      setSalario7(false);
      setSalario8(false);
      setSalario9(false);
      setSalario10(false);
      setSalario11(false);
      setSalario12(false);
      setSalario13(false);
      setSalario14(false);
    } else {
      await Promise.all([
        // ESTADOS
        setNacional(queryParams.nacional === "true"),

        setSaoPaulo(queryParams.saoPaulo === "true"),

        setRioDeJaneiro(queryParams.rioDeJaneiro === "true"),

        setDistritoFederal(queryParams.distritoFederal === "true"),

        setAcre(queryParams.acre === "true"),

        setAlagoas(queryParams.alagoas === "true"),

        setAmapa(queryParams.amapa === "true"),

        setAmazonas(queryParams.amazonas === "true"),

        setBahia(queryParams.bahia === "true"),

        setCeara(queryParams.ceara === "true"),

        setEspiritoSanto(queryParams.espiritoSanto === "true"),

        setGoias(queryParams.goias === "true"),

        setMaranhao(queryParams.maranhao === "true"),

        setMatoGrosso(queryParams.matoGrosso === "true"),

        setMatoGrossoDoSul(queryParams.matoGrossoDoSul === "true"),

        setMinasGerais(queryParams.minasGerais === "true"),

        setPara(queryParams.para === "true"),

        setParaiba(queryParams.paraiba === "true"),

        setParana(queryParams.parana === "true"),

        setPernambuco(queryParams.pernambuco === "true"),

        setPiaui(queryParams.piaui === "true"),

        setRioGrandeDoNorte(queryParams.rioGrandeDoNorte === "true"),

        setRioGrandeDoSul(queryParams.rioGrandeDoSul === "true"),

        setRondonia(queryParams.rondonia === "true"),

        setRoraima(queryParams.roraima === "true"),

        setSantaCatarina(queryParams.santaCatarina === "true"),

        setSergipe(queryParams.sergipe === "true"),

        setTocantins(queryParams.tocantins === "true"),

        // ETAPAS
        setPreInscricao(queryParams.preInscricao === "true"),

        setInscricoesAbertas(queryParams.inscricoesAbertas === "true"),

        setInscricoesEncerradas(queryParams.inscricoesEncerradas === "true"),

        // ESCOLARIDADE
        setSuperior(queryParams.superior === "true"),

        setFundamental(queryParams.fundamental === "true"),

        setMedio(queryParams.medio === "true"),

        setMestrado(queryParams.mestrado === "true"),

        setDoutorado(queryParams.doutorado === "true"),

        // VAGAS
        setCadastroReserva(queryParams.cadastroReserva === "true"),

        setVagaUm(queryParams.vagaUm === "true"),

        setVagaDoisDez(queryParams.vagaDoisDez === "true"),

        setVagaDez(queryParams.vagaDez === "true"),

        setVagaVinteCinco(queryParams.vagaVinteCinco === "true"),

        setVagaCinquenta(queryParams.vagaCinquenta === "true"),

        setVagaCem(queryParams.vagaCem === "true"),

        // SALÁRIO
        setSemRendimentos(queryParams.semRendimentos === "true"),

        setSalario1(queryParams.salario1 === "true"),

        setSalario2(queryParams.salario2 === "true"),

        setSalario3(queryParams.salario3 === "true"),

        setSalario4(queryParams.salario4 === "true"),

        setSalario5(queryParams.salario5 === "true"),

        setSalario6(queryParams.salario6 === "true"),

        setSalario7(queryParams.salario7 === "true"),

        setSalario8(queryParams.salario8 === "true"),

        setSalario9(queryParams.salario9 === "true"),

        setSalario10(queryParams.salario10 === "true"),

        setSalario11(queryParams.salario11 === "true"),

        setSalario12(queryParams.salario12 === "true"),

        setSalario13(queryParams.salario13 === "true"),

        setSalario14(queryParams.salario14 === "true"),

        setOffset(queryParams.offset),
      ]);
    }
  }

  // De acordo com a URL seta as variaveis na primeira vez que o componente carrega
  useEffect(() => {
    if (clean) {
      changingVariables().then(() => setFirstRender(true));
    }
  }, [clean]);

  // Esta função muda a url de acordo com as variaveis e muda o link
  function changeURL() {
    const newQueryParams = {
      offset,
    };
    if (nacional) newQueryParams.nacional = nacional;
    if (saoPaulo) newQueryParams.saoPaulo = saoPaulo;
    if (rioDeJaneiro) newQueryParams.rioDeJaneiro = rioDeJaneiro;
    if (distritoFederal) newQueryParams.distritoFederal = distritoFederal;
    if (acre) newQueryParams.acre = acre;
    if (alagoas) newQueryParams.alagoas = alagoas;
    if (amapa) newQueryParams.amapa = amapa;
    if (amazonas) newQueryParams.amazonas = amazonas;
    if (bahia) newQueryParams.bahia = bahia;
    if (ceara) newQueryParams.ceara = ceara;
    if (espiritoSanto) newQueryParams.espiritoSanto = espiritoSanto;
    if (goias) newQueryParams.goias = goias;
    if (maranhao) newQueryParams.maranhao = maranhao;
    if (matoGrosso) newQueryParams.matoGrosso = matoGrosso;
    if (matoGrossoDoSul) newQueryParams.matoGrossoDoSul = matoGrossoDoSul;
    if (minasGerais) newQueryParams.minasGerais = minasGerais;
    if (para) newQueryParams.para = para;
    if (paraiba) newQueryParams.paraiba = paraiba;
    if (parana) newQueryParams.parana = parana;
    if (pernambuco) newQueryParams.pernambuco = pernambuco;
    if (piaui) newQueryParams.piaui = piaui;
    if (rioGrandeDoNorte) newQueryParams.rioGrandeDoNorte = rioGrandeDoNorte;
    if (rioGrandeDoSul) newQueryParams.rioGrandeDoSul = rioGrandeDoSul;
    if (rondonia) newQueryParams.rondonia = rondonia;
    if (roraima) newQueryParams.roraima = roraima;
    if (santaCatarina) newQueryParams.santaCatarina = santaCatarina;
    if (sergipe) newQueryParams.sergipe = sergipe;
    if (tocantins) newQueryParams.tocantins = tocantins;

    if (preInscricao) newQueryParams.preInscricao = preInscricao;
    if (inscricoesAbertas) newQueryParams.inscricoesAbertas = inscricoesAbertas;
    if (inscricoesEncerradas)
      newQueryParams.inscricoesEncerradas = inscricoesEncerradas;
    if (superior) newQueryParams.superior = superior;
    if (fundamental) newQueryParams.fundamental = fundamental;
    if (medio) newQueryParams.medio = medio;
    if (mestrado) newQueryParams.mestrado = mestrado;
    if (doutorado) newQueryParams.doutorado = doutorado;
    if (cadastroReserva) newQueryParams.cadastroReserva = cadastroReserva;
    if (vagaUm) newQueryParams.vagaUm = vagaUm;
    if (vagaDoisDez) newQueryParams.vagaDoisDez = vagaDoisDez;
    if (vagaDez) newQueryParams.vagaDez = vagaDez;
    if (vagaVinteCinco) newQueryParams.vagaVinteCinco = vagaVinteCinco;
    if (vagaCinquenta) newQueryParams.vagaCinquenta = vagaCinquenta;
    if (vagaCem) newQueryParams.vagaCem = vagaCem;
    if (semRendimentos) newQueryParams.semRendimentos = semRendimentos;
    if (salario1) newQueryParams.salario1 = salario1;
    if (salario2) newQueryParams.salario2 = salario2;
    if (salario3) newQueryParams.salario3 = salario3;
    if (salario4) newQueryParams.salario4 = salario4;
    if (salario5) newQueryParams.salario5 = salario5;
    if (salario6) newQueryParams.salario6 = salario6;
    if (salario7) newQueryParams.salario7 = salario7;
    if (salario8) newQueryParams.salario8 = salario8;
    if (salario9) newQueryParams.salario9 = salario9;
    if (salario10) newQueryParams.salario10 = salario10;
    if (salario11) newQueryParams.salario11 = salario11;
    if (salario12) newQueryParams.salario12 = salario12;
    if (salario13) newQueryParams.salario13 = salario13;
    if (salario14) newQueryParams.salario14 = salario14;
    // searchString passa por um stringfy para converter os objetos que bateram com a URL em chave valor na URL iguais
    const searchString = queryString.stringify(newQueryParams);
    // Em seguida ele navega pra esse link salvando assim o estado que está
    navigate(`${location.pathname}?${searchString}`);
  }

  // Requisição usa o link passado como parâmetro para pedir uma resposta a API, usa o que vier no results na parte de renderização e o count para o contador,
  // depois testa se o count é menor ou igual a 1 e seta o texto pro singular ou plural
  async function requisicao(apiLink) {
    fetch(apiLink, {
      headers: {
        Authorization: `Token ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        setRenderizacao(response.results);
        response.results.length > 0 ? setCount(response.count) : setCount(0);

        if (response.count <= 1) {
          setCargoText("resultado");
          setFoundText("encontrado");
        } else {
          setCargoText("resultados");
          setFoundText("encontrados");
        }
      });
  }

  // Cria uma matriz de objetos chamada Filtros, cada objeto tem uma chave principal que descreve o tipo de filtro, por exemplo Estados, ensino, etapas...
  // Dentro de cada objeto tem uma chave chamada "chave" que é o prefixo usado na API para aquele filtro
  // Em valores dentro de cada objeto temos uma nova matriz, onde cada objeto é um valor especifico desse filtro, por exemplo são paulo é 33 no objeto estados

  const filtros = [
    {
      estados: {
        chave: "&location_work_state=",
        valores: [
          { estado: "12", variavel: [nacional] },
          { estado: "35", variavel: [saoPaulo] },
          { estado: "33", variavel: [rioDeJaneiro] },
          { estado: "53", variavel: [distritoFederal] },
          { estado: "12", variavel: [acre] },
          { estado: "27", variavel: [alagoas] },
          { estado: "16", variavel: [amapa] },
          { estado: "13", variavel: [amazonas] },
          { estado: "29", variavel: [bahia] },
          { estado: "23", variavel: [ceara] },
          { estado: "32", variavel: [espiritoSanto] },
          { estado: "52", variavel: [goias] },
          { estado: "21", variavel: [maranhao] },
          { estado: "51", variavel: [matoGrosso] },
          { estado: "50", variavel: [matoGrossoDoSul] },
          { estado: "31", variavel: [minasGerais] },
          { estado: "15", variavel: [para] },
          { estado: "25", variavel: [paraiba] },
          { estado: "41", variavel: [parana] },
          { estado: "26", variavel: [pernambuco] },
          { estado: "22", variavel: [piaui] },
          { estado: "24", variavel: [rioGrandeDoNorte] },
          { estado: "43", variavel: [rioGrandeDoSul] },
          { estado: "11", variavel: [rondonia] },
          { estado: "14", variavel: [roraima] },
          { estado: "42", variavel: [santaCatarina] },
          { estado: "28", variavel: [sergipe] },
          { estado: "17", variavel: [tocantins] },
        ],
      },
    },
    {
      ensino: {
        chave: "&require_schooling=",
        valores: [
          { valor: "4", variaveis: [superior] },
          { valor: "1", variaveis: [fundamental] },
          { valor: "2", variaveis: [medio] },
          { valor: "6", variaveis: [mestrado] },
          { valor: "7", variaveis: [doutorado] },
        ],
      },
    },
    {
      etapas: {
        chave: "&stage=",
        valores: [
          { valor: "1", variaveis: [preInscricao] },
          { valor: "2", variaveis: [inscricoesAbertas] },
          { valor: "3", variaveis: [inscricoesEncerradas] },
        ],
      },
    },
  ];

  // Esta função tem o objetivo de estabelecer uma query para a API. Ela inicia a variavel com a requisição básica para a API,
  // ela percorre a matriz Filtros e se uma das variáveis dos valores for true ela muda a variavel para o valor que ela já tinha + os novos parâmetros, no fim retorna a query
  function buildQueryFromFilters() {
    let queryForApi = `${URL}/contest/contest/?limit=${cardLimitPerPage}&offset=${offset}`;

    // Estados
    for (let i = 0; i < filtros[0].estados.valores.length; i++) {
      if (filtros[0].estados.valores[i].variavel[0]) {
        queryForApi =
          queryForApi +
          filtros[0].estados.chave +
          filtros[0].estados.valores[i].estado;
      }
    }

    // Ensino
    for (let i = 0; i < filtros[1].ensino.valores.length; i++) {
      if (filtros[1].ensino.valores[i].variaveis[0]) {
        queryForApi =
          queryForApi +
          filtros[1].ensino.chave +
          filtros[1].ensino.valores[i].valor;
      }
    }

    // Etapas
    for (let i = 0; i < filtros[2].etapas.valores.length; i++) {
      if (filtros[2].etapas.valores[i].variaveis[0]) {
        queryForApi =
          queryForApi +
          filtros[2].etapas.chave +
          filtros[2].etapas.valores[i].valor;
      }
    }

    // Vagas
    if (vacancyValue[1] === 1000) {
      queryForApi =
        queryForApi + `&vacancy_range=${vacancyValue[0]}%2C${500000}`;
    } else {
      queryForApi =
        queryForApi + `&vacancy_range=${vacancyValue[0]}%2C${vacancyValue[1]}`;
    }
    // Salario
    if (wageValue[1] === 50000) {
      queryForApi = queryForApi + `&wage_range=${wageValue[0]}%2C${100000}`;
    } else {
      queryForApi =
        queryForApi + `&wage_range=${wageValue[0]}%2C${wageValue[1]}`;
    }

    return queryForApi;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [offset]);

  useEffect(() => {
    if (firstRender) {
      let apiRequisition = buildQueryFromFilters();
      requisicao(apiRequisition);
      changeURL();
    }
  }, [
    preInscricao,
    inscricoesAbertas,
    inscricoesEncerradas,
    superior,
    fundamental,
    medio,
    mestrado,
    doutorado,
    cadastroReserva,
    vagaUm,
    vagaDoisDez,
    vagaDez,
    vagaVinteCinco,
    vagaCinquenta,
    vagaCem,
    semRendimentos,
    salario1,
    salario2,
    salario3,
    salario4,
    salario5,
    salario6,
    salario7,
    salario8,
    salario9,
    salario10,
    salario11,
    salario12,
    salario13,
    salario14,
    nacional,
    saoPaulo,
    rioDeJaneiro,
    distritoFederal,
    acre,
    alagoas,
    amapa,
    amazonas,
    bahia,
    ceara,
    espiritoSanto,
    goias,
    maranhao,
    matoGrosso,
    matoGrossoDoSul,
    minasGerais,
    para,
    paraiba,
    parana,
    pernambuco,
    piaui,
    rioGrandeDoNorte,
    rioGrandeDoSul,
    rondonia,
    roraima,
    santaCatarina,
    sergipe,
    tocantins,
    offset,
    firstRender,
    wageValue,
    vacancyValue,
  ]);

  useEffect(() => {}, [renderizacao]);

  useEffect(() => {
    setOffset(0);
  }, [
    preInscricao,
    inscricoesAbertas,
    inscricoesEncerradas,
    superior,
    fundamental,
    medio,
    mestrado,
    doutorado,
    cadastroReserva,
    vagaUm,
    vagaDoisDez,
    vagaDez,
    vagaVinteCinco,
    vagaCinquenta,
    vagaCem,
    semRendimentos,
    salario1,
    salario2,
    salario3,
    salario4,
    salario5,
    salario6,
    salario7,
    salario8,
    salario9,
    salario10,
    salario11,
    salario12,
    salario13,
    salario14,
    nacional,
    saoPaulo,
    rioDeJaneiro,
    distritoFederal,
    acre,
    alagoas,
    amapa,
    amazonas,
    bahia,
    ceara,
    espiritoSanto,
    goias,
    maranhao,
    matoGrosso,
    matoGrossoDoSul,
    minasGerais,
    para,
    paraiba,
    parana,
    pernambuco,
    piaui,
    rioGrandeDoNorte,
    rioGrandeDoSul,
    rondonia,
    roraima,
    santaCatarina,
    sergipe,
    tocantins,
  ]);

  return (
    <StyledConcursos className="Contests">
      <SecondSearchBar
        page="Concursos"
        setTermoPesquisado={setTermoPesquisado}
        termoPesquisado={termoPesquisado}
        setSearchReset={setSearchReset}
        searchReset={searchReset}
      />
      <FilterHide setOpen={setOpen} open={open} />
      <section className="SectionCard">
        {window.innerWidth <= 969 ? (
          open && (
            <Filter
              setWageValue={setWageValue}
              setVacancyValue={setVacancyValue}
              overlay={overlay}
              setOverlay={setOverlay}
              estados={estados}
              faseConcurso={faseConcurso}
              vagas={vagas}
              salario={salario}
              setPreInscricao={setPreInscricao}
              preInscricao={preInscricao}
              setInscricoesAbertas={setInscricoesAbertas}
              inscricoesAbertas={inscricoesAbertas}
              setInscricoesEncerradas={setInscricoesEncerradas}
              inscricoesEncerradas={inscricoesEncerradas}
              setCadastroReserva={setCadastroReserva}
              cadastroReserva={cadastroReserva}
              vagaUm={vagaUm}
              setVagaUm={setVagaUm}
              vagaDoisDez={vagaDoisDez}
              setVagaDoisDez={setVagaDoisDez}
              vagaDez={vagaDez}
              setVagaDez={setVagaDez}
              vagaVinteCinco={vagaVinteCinco}
              setVagaVinteCinco={setVagaVinteCinco}
              vagaCinquenta={vagaCinquenta}
              setVagaCinquenta={setVagaCinquenta}
              vagaCem={vagaCem}
              setVagaCem={setVagaCem}
              semRendimentos={semRendimentos}
              setSemRendimentos={setSemRendimentos}
              salario1={salario1}
              setSalario1={setSalario1}
              salario2={salario2}
              setSalario2={setSalario2}
              salario3={salario3}
              setSalario3={setSalario3}
              salario4={salario4}
              setSalario4={setSalario4}
              salario5={salario5}
              setSalario5={setSalario5}
              salario6={salario6}
              setSalario6={setSalario6}
              salario7={salario7}
              setSalario7={setSalario7}
              salario8={salario8}
              setSalario8={setSalario8}
              salario9={salario9}
              setSalario9={setSalario9}
              salario10={salario10}
              setSalario10={setSalario10}
              salario11={salario11}
              setSalario11={setSalario11}
              salario12={salario12}
              setSalario12={setSalario12}
              salario13={salario13}
              setSalario13={setSalario13}
              salario14={salario14}
              setSalario14={setSalario14}
              nacional={nacional}
              saoPaulo={saoPaulo}
              rioDeJaneiro={rioDeJaneiro}
              distritoFederal={distritoFederal}
              acre={acre}
              alagoas={alagoas}
              amapa={amapa}
              amazonas={amazonas}
              bahia={bahia}
              ceara={ceara}
              espiritoSanto={espiritoSanto}
              goias={goias}
              maranhao={maranhao}
              matoGrosso={matoGrosso}
              matoGrossoDoSul={matoGrossoDoSul}
              minasGerais={minasGerais}
              para={para}
              paraiba={paraiba}
              parana={parana}
              pernambuco={pernambuco}
              piaui={piaui}
              rioGrandeDoNorte={rioGrandeDoNorte}
              rioGrandeDoSul={rioGrandeDoSul}
              rondonia={rondonia}
              roraima={roraima}
              santaCatarina={santaCatarina}
              sergipe={sergipe}
              tocantins={tocantins}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
            />
          )
        ) : (
          <Filter
            setWageValue={setWageValue}
            setVacancyValue={setVacancyValue}
            overlay={overlay}
            setOverlay={setOverlay}
            estados={estados}
            faseConcurso={faseConcurso}
            vagas={vagas}
            salario={salario}
            setPreInscricao={setPreInscricao}
            preInscricao={preInscricao}
            setInscricoesAbertas={setInscricoesAbertas}
            inscricoesAbertas={inscricoesAbertas}
            setInscricoesEncerradas={setInscricoesEncerradas}
            inscricoesEncerradas={inscricoesEncerradas}
            setCadastroReserva={setCadastroReserva}
            cadastroReserva={cadastroReserva}
            vagaUm={vagaUm}
            setVagaUm={setVagaUm}
            vagaDoisDez={vagaDoisDez}
            setVagaDoisDez={setVagaDoisDez}
            vagaDez={vagaDez}
            setVagaDez={setVagaDez}
            vagaVinteCinco={vagaVinteCinco}
            setVagaVinteCinco={setVagaVinteCinco}
            vagaCinquenta={vagaCinquenta}
            setVagaCinquenta={setVagaCinquenta}
            vagaCem={vagaCem}
            setVagaCem={setVagaCem}
            semRendimentos={semRendimentos}
            setSemRendimentos={setSemRendimentos}
            salario1={salario1}
            setSalario1={setSalario1}
            salario2={salario2}
            setSalario2={setSalario2}
            salario3={salario3}
            setSalario3={setSalario3}
            salario4={salario4}
            setSalario4={setSalario4}
            salario5={salario5}
            setSalario5={setSalario5}
            salario6={salario6}
            setSalario6={setSalario6}
            salario7={salario7}
            setSalario7={setSalario7}
            salario8={salario8}
            setSalario8={setSalario8}
            salario9={salario9}
            setSalario9={setSalario9}
            salario10={salario10}
            setSalario10={setSalario10}
            salario11={salario11}
            setSalario11={setSalario11}
            salario12={salario12}
            setSalario12={setSalario12}
            salario13={salario13}
            setSalario13={setSalario13}
            salario14={salario14}
            setSalario14={setSalario14}
            nacional={nacional}
            saoPaulo={saoPaulo}
            rioDeJaneiro={rioDeJaneiro}
            distritoFederal={distritoFederal}
            acre={acre}
            alagoas={alagoas}
            amapa={amapa}
            amazonas={amazonas}
            bahia={bahia}
            ceara={ceara}
            espiritoSanto={espiritoSanto}
            goias={goias}
            maranhao={maranhao}
            matoGrosso={matoGrosso}
            matoGrossoDoSul={matoGrossoDoSul}
            minasGerais={minasGerais}
            para={para}
            paraiba={paraiba}
            parana={parana}
            pernambuco={pernambuco}
            piaui={piaui}
            rioGrandeDoNorte={rioGrandeDoNorte}
            rioGrandeDoSul={rioGrandeDoSul}
            rondonia={rondonia}
            roraima={roraima}
            santaCatarina={santaCatarina}
            sergipe={sergipe}
            tocantins={tocantins}
            setNacional={setNacional}
            setSaoPaulo={setSaoPaulo}
            setRioDeJaneiro={setRioDeJaneiro}
            setDistritoFederal={setDistritoFederal}
            setAcre={setAcre}
            setAlagoas={setAlagoas}
            setAmapa={setAmapa}
            setAmazonas={setAmazonas}
            setBahia={setBahia}
            setCeara={setCeara}
            setEspiritoSanto={setEspiritoSanto}
            setGoias={setGoias}
            setMaranhao={setMaranhao}
            setMatoGrosso={setMatoGrosso}
            setMatoGrossoDoSul={setMatoGrossoDoSul}
            setMinasGerais={setMinasGerais}
            setPara={setPara}
            setParaiba={setParaiba}
            setParana={setParana}
            setPernambuco={setPernambuco}
            setPiaui={setPiaui}
            setRioGrandeDoNorte={setRioGrandeDoNorte}
            setRioGrandeDoSul={setRioGrandeDoSul}
            setRondonia={setRondonia}
            setRoraima={setRoraima}
            setSantaCatarina={setSantaCatarina}
            setSergipe={setSergipe}
            setTocantins={setTocantins}
          />
        )}

        <div className="CardMenu">
          {count === 0 || count ? (
            <div className="flexTituloAlerta">
              <h3 className="tituloPrincipal">
                {count} {cargoText} {foundText} para concursos
              </h3>
              <Link
                className="tagButton"
                to="/conta"
                state={{ from: "alerta" }}
              >{`Criar alerta`}</Link>
            </div>
          ) : (
            <Skeleton width={"582.594px"} height={"38px"} />
          )}

          <section className="cardConcursoGrid">
            {renderizacao
              ? renderizacao.lenght === 0
                ? null
                : renderizacao.map((item, index) => {
                    return (
                      <CardConcurso
                        name={item.short_name}
                        location={
                          item.location_work_detail
                            ? item.location_work_detail.name
                            : "Não informado"
                        }
                        wageRange={item.max_wage}
                        vacancy={item.vacancies}
                        registration_opening={item.registration_opening}
                        registrationDeadline={item.registration_deadline}
                        id={item.id}
                        slug={item.slug}
                        imagemConcurso={item.icon ? item.icon.svg_url : null}
                        contest_number={item.contest_number}
                        key={index}
                      />
                    );
                  })
              : skeleton
              ? Array.from({ length: cardLimitPerPage }).map((_, index) => {
                  return (
                    <Skeleton key={index} width={"100%"} height={"100%"} />
                  );
                })
              : null}
          </section>
        </div>

        {count ? (
          <Pagination
            setCurrentPage={setCurrentPage}
            limit={cardLimitPerPage}
            total={count}
            offset={offset}
            setOffset={setOffset}
          />
        ) : null}
      </section>
    </StyledConcursos>
  );
}

export default Concursos;
