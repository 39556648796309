import { StyledCardStiky } from "../styles/CardStiky";
import { useState } from "react";

function CardSticky({ titulo, subTitulo, balao }) {
  const [onHover, setOnHover] = useState("none");

  return (
    <StyledCardStiky
      onMouseEnter={() => setOnHover("flex")}
      onMouseLeave={() => setOnHover("none")}
      className="CardStickyC"
    >
      <h2>{titulo}</h2>
      <p>{subTitulo ? subTitulo : null}</p>
      {balao ? (
        <div style={{ display: onHover }} className="balaoCardSticky">
          {balao}
        </div>
      ) : null}
    </StyledCardStiky>
  );
}

export default CardSticky;
