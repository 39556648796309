import styled from "styled-components";

export const StyledCarregando = styled.main`
  min-height: calc(100vh - var(--NewFooterHeight));
  position: relative;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 52px;
    height: 52px;
    border: 5px solid #fff;
    border-bottom-color: var(--Primary-500);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
