import React, { useEffect, useRef } from "react";
import { useState } from "react";
import angledown from "../assets/angledown.svg";

import { conversaoData } from "../tools/functions";

import trashxmark from "../assets/trashxmark.svg";
import fileEdit from "../assets/fileEdit.svg";

function AlertCard({ alert, setRecarregar, recarregar }) {
  const [alertCardOpen, setAlertCardOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const Token = process.env.REACT_APP_TOKEN;
  const URL = process.env.REACT_APP_API_URL;

  const [suggestionContestValue, setSuggestionContestValue] = useState(
    alert.contest_tag_name
  );
  const [suggestionLocationValue, setSuggestionLocationValue] = useState("");
  const [suggestionOfficeValue, setSuggestionOfficeValue] = useState(
    alert.office_tag_name
  );

  // Name
  const [suggestionName, setSuggestionName] = useState(alert.name);
  // ESTADOS
  const [pesquisaEstado, setPesquisaEstado] = useState("");
  const [estadoSelecionado, setEstadoSelecionado] = useState(null);
  const [showList, setShowList] = useState(false);

  // CIDADES
  const [listaDeCidades, setListaDeCidades] = useState([]);
  const [listaDeCidadesName, setListaDeCidadesName] = useState([]);

  const [pesquisaCidade, setPesquisaCidade] = useState("");
  const [cidadeSelecionada, setCidadeSelecionada] = useState(null);
  const [showListCidade, setShowListCidade] = useState(false);

  // CARGOS
  const [listaDeCargos, setListaDeCargos] = useState([]);
  const [listaDeCargosName, setListaDeCargosName] = useState([]);
  const [showListCargos, setShowListCargos] = useState(false);

  // CONCURSOS
  const [listaDeConcursos, setListaDeConcursos] = useState([]);
  const [listaDeConcursosName, setListaDeConcursosName] = useState([]);
  const [showListConcursos, setShowListConcursos] = useState(false);

  const refEstadosEdit = useRef(null);
  const refCidadesEdit = useRef(null);
  const refCargosEdit = useRef(null);
  const refConcursosEdit = useRef(null);

  async function deletar() {
    await fetch(`${URL}/alerts/alerts/${alert.id}/`, {
      method: "DELETE",
      headers: {
        Authorization: `Token ${Token}`,
      },
    }).then((response) => {
      if (response.ok) {
        setRecarregar(!recarregar);
        setIsEditing(false);
      }
    });
  }

  const cargosOutside = (event) => {
    if (
      refCargosEdit.current &&
      !refCargosEdit.current.contains(event.target)
    ) {
      setShowListCargos(false);
    }
  };
  const concursosOutside = (event) => {
    if (
      refConcursosEdit.current &&
      !refConcursosEdit.current.contains(event.target)
    ) {
      setShowListConcursos(false);
    }
  };

  const estadosOutside = (event) => {
    if (
      refEstadosEdit.current &&
      !refEstadosEdit.current.contains(event.target)
    ) {
      setShowList(false);
    }
  };
  const cidadesOutside = (event) => {
    if (
      refCidadesEdit.current &&
      !refCidadesEdit.current.contains(event.target)
    ) {
      setShowListCidade(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", cargosOutside);
    return () => {
      document.removeEventListener("mousedown", cargosOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", concursosOutside);
    return () => {
      document.removeEventListener("mousedown", concursosOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", estadosOutside);
    return () => {
      document.removeEventListener("mousedown", estadosOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", cidadesOutside);
    return () => {
      document.removeEventListener("mousedown", cidadesOutside);
    };
  }, []);

  useEffect(() => {
    async function requisicao(apiLink, type) {
      await fetch(apiLink, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (type === "location") {
            setListaDeCidades(response.results);
          }
        });
    }

    let estadoGet = obterSigla(estadoSelecionado);

    requisicao(
      `${URL}/location/location/?limit=1000&state=${estadoGet}`,
      "location"
    );
  }, [estadoSelecionado, URL, Token]);

  useEffect(() => {
    setListaDeCidadesName(listaDeCidades.map((item) => item.name));
  }, [listaDeCidades]);

  useEffect(() => {
    async function requisicao(apiLink, type) {
      await fetch(apiLink, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (type === "officeTag") {
            setListaDeCargos(response);
          }
          if (type === "contestTag") {
            setListaDeConcursos(response);
          }
        });
    }

    requisicao(`${URL}/office/top-tags/`, "officeTag");
    requisicao(`${URL}/contest/top-tags/`, "contestTag");
  }, []);

  useEffect(() => {
    setListaDeCargosName(listaDeCargos.map((item) => item.name));
  }, [listaDeCargos]);

  useEffect(() => {
    setListaDeConcursosName(listaDeConcursos.map((item) => item.name));
  }, [listaDeConcursos]);

  async function concluirEdicao() {
    const formData = {
      name: suggestionName,
      office_tag_name: suggestionOfficeValue,
      contest_tag_name: suggestionContestValue,
      location_name: pesquisaCidade,
    };
    console.log(formData);
    await fetch(`${URL}/alerts/alerts/${alert.id}/`, {
      method: "PATCH",
      headers: {
        Authorization: `Token ${Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((response) => {
      if (response.ok) {
        setRecarregar(!recarregar);
        setIsEditing(false);
      }
    });
  }

  const handleCityClick = (cidade) => {
    setCidadeSelecionada(cidade);
    setPesquisaCidade(cidade);
    setShowListCidade(false);
  };

  const estadosBrasil = [
    "Acre",
    "Alagoas",
    "Amapá",
    "Amazonas",
    "Bahia",
    "Ceará",
    "Distrito Federal",
    "Espírito Santo",
    "Goiás",
    "Maranhão",
    "Mato Grosso",
    "Mato Grosso do Sul",
    "Minas Gerais",
    "Pará",
    "Paraíba",
    "Paraná",
    "Pernambuco",
    "Piauí",
    "Rio de Janeiro",
    "Rio Grande do Norte",
    "Rio Grande do Sul",
    "Rondônia",
    "Roraima",
    "Santa Catarina",
    "São Paulo",
    "Sergipe",
    "Tocantins",
  ];
  const siglasEstados = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  const handleStateClick = (estado) => {
    setEstadoSelecionado(estado);
    setPesquisaEstado(estado);
    setShowList(false);
  };

  function obterSigla(estado) {
    const index = estadosBrasil.indexOf(estado);

    if (index !== -1) {
      return siglasEstados[index];
    }
  }
  // filtros digitando nos inputs

  const filteredStates = estadosBrasil.filter((estado) =>
    estado.toLowerCase().includes(pesquisaEstado.toLowerCase())
  );
  const filteredCity = listaDeCidadesName.filter((cidade) =>
    cidade.toLowerCase().includes(pesquisaCidade.toLowerCase())
  );

  const filteredOffices = listaDeCargosName.filter((cargo) =>
    cargo.toLowerCase().includes(suggestionOfficeValue.toLowerCase())
  );

  const filteredContest = listaDeConcursosName.filter((concurso) =>
    concurso.toLowerCase().includes(suggestionContestValue.toLowerCase())
  );

  return (
    <div
      className="cardAlertComplete"
      // style={
      //   alertCardOpen ? { padding: "64px 32px" } : { padding: "12px 16px" }
      // }
    >
      <div className="filterAlertDone">
        <div>
          {isEditing ? (
            <input
              type="text"
              name="name"
              value={suggestionName}
              onChange={(e) => setSuggestionName(e.target.value)}
              autocomplete="off"
            />
          ) : (
            <h4>{alert.name}</h4>
          )}

          <p>{`Última atualização: ${conversaoData(alert.last_modified)}`}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <button
            onClick={() => setAlertCardOpen(!alertCardOpen)}
            className="angleDown"
          >
            <img
              style={
                alertCardOpen
                  ? { transform: "rotate(-90deg)" }
                  : { transform: "rotate(0deg)" }
              }
              src={angledown}
              alt="seta para baixo"
            />
          </button>
        </div>
      </div>

      <div
        className="filterOpenPart"
        style={alertCardOpen ? { height: "43.1rem" } : { height: "0" }}
      >
        <hr />
        <h2>Filtros do alerta</h2>
        {alert.office_tag_name && (
          <div>
            <h3>Etiquetas de cargos</h3>

            {isEditing ? (
              <div ref={refCargosEdit}>
                <input
                  type="text"
                  name="office_tag_name"
                  value={suggestionOfficeValue}
                  onChange={(e) => setSuggestionOfficeValue(e.target.value)}
                  onFocus={() => setShowListCargos(true)}
                />
                {showListCargos && (
                  <ul
                    className="ulOption"
                    onClick={() => setShowListCargos(false)}
                  >
                    {filteredOffices.map((cargo) => (
                      <li
                        key={cargo}
                        onClick={() => setSuggestionOfficeValue(cargo)}
                      >
                        {cargo}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ) : (
              <p>{alert.office_tag_name}</p>
            )}
          </div>
        )}
        {alert.contest_tag_name && (
          <div>
            <h3>Etiquetas de concursos</h3>

            {isEditing ? (
              <div ref={refConcursosEdit}>
                <input
                  type="text"
                  name="contest_tag_name"
                  onChange={(e) => setSuggestionContestValue(e.target.value)}
                  value={suggestionContestValue}
                  onFocus={() => setShowListConcursos(true)}
                  autocomplete="off"
                />
                {showListConcursos && (
                  <ul
                    className="ulOption"
                    onClick={() => setShowListConcursos(false)}
                  >
                    {filteredContest.map((concurso) => (
                      <li
                        key={concurso}
                        onClick={() => setSuggestionContestValue(concurso)}
                      >
                        {concurso}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ) : (
              <p>{alert.contest_tag_name}</p>
            )}
          </div>
        )}
        {alert.location_name && (
          <div>
            <h3>Etiquetas de cidades</h3>

            {isEditing ? (
              <div ref={refEstadosEdit}>
                <input
                  type="text"
                  id="search"
                  value={pesquisaEstado}
                  onChange={(e) => setPesquisaEstado(e.target.value)}
                  onFocus={() => setShowList(true)}
                  placeholder="Digite o nome do estado"
                  autocomplete="off"
                />
                {showList && (
                  <ul className="ulOption">
                    {filteredStates.map((estado) => (
                      <li key={estado} onClick={() => handleStateClick(estado)}>
                        {estado}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ) : (
              <p>{alert.location_name}</p>
            )}
            {isEditing && (
              <div ref={refCidadesEdit}>
                <input
                  type="text"
                  value={pesquisaCidade}
                  onChange={(e) => setPesquisaCidade(e.target.value)}
                  onFocus={() => setShowListCidade(true)}
                  disabled={!pesquisaEstado}
                  autocomplete="off"
                />
                {showListCidade && (
                  <ul className="ulOption">
                    {filteredCity.map((cidade, index) => (
                      <li key={index} onClick={() => handleCityClick(cidade)}>
                        {cidade}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        )}

        <hr />
        <div className="buttonsEditTrash">
          <button onClick={() => deletar()} className="trash">
            <img src={trashxmark} alt="lixeira" />
            <p>Deletar</p>
          </button>
          {isEditing ? (
            <button onClick={concluirEdicao} className="concluir">
              <img src={fileEdit} alt="concluir" />
              <p>Concluir</p>
            </button>
          ) : (
            <button onClick={() => setIsEditing(true)} className="edit">
              <img src={fileEdit} alt="editar" />
              <p>Editar</p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AlertCard;
