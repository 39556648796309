import styled from "styled-components";

export const StyledUserAccount = styled.main`
  background-color: #f7f8ff;
  min-height: calc(100vh - var(--FooterHeight));
  .mobileFlexBack {
    display: none;
  }
  section {
    display: flex;
    margin: 9.6rem auto;
    width: 112rem;
    .flexLeft {
      display: flex;
      flex-direction: column;
      min-width: 320px;
      align-items: flex-start;
      gap: var(--Scale-8, 64px);
      .flexBack {
        display: flex;
        align-items: center;
        gap: 1rem;
        align-self: start;
        svg {
          width: 22px;
          height: 22px;
        }
        a {
          color: var(--Primary-500, #1b2cc1);
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: 0.16px;
          cursor: pointer;
          text-decoration: none;
        }
      }

      .flexNav {
        display: flex;
        flex-direction: column;
        align-items: start;
      }
    }
    .flexRight {
      flex-grow: 1;
    }
  }

  @media (max-width: 1175px) {
    section {
      width: 90%;
    }
  }
  @media (max-width: 980px) {
    section {
      .flexLeft {
        width: 18.3rem;
      }
    }
  }
  @media (max-width: 970px) {
    section {
      flex-direction: column;
      gap: 4rem;
      .flexLeft {
        width: 100%;
        align-items: center;
        gap: 3.2rem;
      }
    }
    @keyframes top {
      0% {
        top: 0;
        transform: rotate(0);
      }
      50% {
        top: 9px;
        transform: rotate(0);
      }
      100% {
        top: 9px;
        transform: rotate(45deg);
      }
    }

    @keyframes top-2 {
      0% {
        top: 9px;
        transform: rotate(45deg);
      }
      50% {
        top: 9px;
        transform: rotate(0deg);
      }
      100% {
        top: 0;
        transform: rotate(0deg);
      }
    }

    @keyframes bottom {
      0% {
        bottom: 0;
        transform: rotate(0);
      }
      50% {
        bottom: 9px;
        transform: rotate(0);
      }
      100% {
        bottom: 9px;
        transform: rotate(135deg);
      }
    }

    @keyframes bottom-2 {
      0% {
        bottom: 9px;
        transform: rotate(135deg);
      }
      50% {
        bottom: 9px;
        transform: rotate(0);
      }
      100% {
        bottom: 0;
        transform: rotate(0);
      }
    }

    @keyframes scaled {
      50% {
        transform: scale(0);
      }
      100% {
        transform: scale(0);
      }
    }

    @keyframes scaled-2 {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }

    .desktop {
      display: none !important;
    }
    .mobileFlexBack {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .flexBack {
        display: flex;
        align-items: center;
        gap: 1rem;
        align-self: start;
        svg {
          width: 22px;
          height: 22px;
        }
        a {
          color: var(--Primary-500, #1b2cc1);
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: 0.16px;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
    .menuHamburguer {
      display: block;
      width: 2.4rem;
      cursor: pointer;
      span {
        display: block;
        width: 100%;
        border-radius: 3px;
        height: 2px;
        background: #aaaaf0;
        transition: all 0.3s;
        position: relative;
      }
      span + span {
        margin-top: 0.7rem; // espaçamento entre as barras
      }
    }
    .true span:nth-child(1) {
      animation: ease 0.5s top forwards;
    }

    .false span:nth-child(1) {
      animation: ease 0.5s top-2 forwards;
    }

    .true span:nth-child(2) {
      animation: ease 0.5s scaled forwards;
    }

    .false span:nth-child(2) {
      animation: ease 0.5s scaled-2 forwards;
    }

    .true span:nth-child(3) {
      animation: ease 0.5s bottom forwards;
    }

    .false span:nth-child(3) {
      animation: ease 0.5s bottom-2 forwards;
    }
  }
`;
