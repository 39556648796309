import { StyledCard } from "../styles/Card";
import CardSticky from "../UI/CardSticky";
import Tag from "../UI/Tag";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toTitleCase } from "../tools/functions";

// import lock from "../assets/lock.svg";
import {
  convertMoney,
  conversaoData,
  conversaoDia,
  conversaoSalario,
  compararDatasNome,
  compararDatasClasse,
} from "../tools/functions";

function Card({
  buttonExist,
  recarregarComTag,
  setRecarregarComTag,
  display_name,
  working_hours,
  diaDaProva,
  icone,
  vacancies,
  organization,
  wage,
  registration_opening,
  registration_deadline,
  taxa,
  local,
  tagsmy,
  short_name,
  banca,
  cssAdd,
  slugLink,
}) {
  const [lista_de_datas, setListaDeDatas] = useState([]);

  useEffect(() => {
    if (diaDaProva) {
      let valor_data = conversaoData(diaDaProva).split("/");

      if (valor_data[2] === 20 || valor_data[2] === "20") {
        setListaDeDatas([]);
      } else {
        setListaDeDatas(valor_data);
      }
    } else {
      setListaDeDatas([]);
    }
  }, [diaDaProva]);
  return (
    <StyledCard className={`Card ${cssAdd}`}>
      {/* 1 parte - Tags */}
      <div className="flexTags">
        <Tag
          recarregarComTag={recarregarComTag}
          setRecarregarComTag={setRecarregarComTag}
          nome={compararDatasNome(registration_opening, registration_deadline)}
          classname={compararDatasClasse(
            registration_opening,
            registration_deadline
          )}
        />
      </div>
      {/* 2 parte - imagem e local */}
      <div className="imageAndLocal">
        {/* <IconeSVG svg={icone} cor={"red"}/> */}
        <img src={icone} alt="icone" />
        {/* <svg xmlns={icone}></svg> */}
        <h4>{short_name}</h4>
      </div>
      {/* 3 parte - Nome e Superior selecionados */}
      <div className="flexNameAndSuperior">
        <h2 className="contestName">{toTitleCase(display_name)}</h2>
        <div className="flexSuperior">
          {/* <p className='superiorSelected'>superior selecionados</p> */}
          {tagsmy
            ? tagsmy.map((item, index) => {
                return (
                  <Tag
                    recarregarComTag={recarregarComTag}
                    setRecarregarComTag={setRecarregarComTag}
                    key={index}
                    nome={item.name}
                    classname={"tagNormal"}
                  />
                );
              })
            : null}
          {/* tag de momento do concurso */}
          {/* edital publicado, inscrições abertas, inscrições encerradas */}
          {/* <Icone imagem={error1}/> */}
        </div>
      </div>
      {/* 4 parte - TripleData */}
      <div className="tripleData">
        <CardSticky
          titulo={vacancies === 0 ? "CR" : vacancies}
          subTitulo={
            vacancies === 1 ? "Vaga" : vacancies === 0 ? "vagas" : "Vagas"
          }
          balao={vacancies === 0 ? "Cadastro de Reserva" : null}
        />
        {/* <CardSticky titulo={banca} subTitulo={"Banca"}/> */}
        <CardSticky
          titulo={working_hours ? working_hours : "-"}
          subTitulo={working_hours ? "Horas semanais" : null}
        />
        <CardSticky
          titulo={`${conversaoSalario(wage)}K+`}
          subTitulo={"Salário"}
        />
      </div>
      {/* 5 parte - miscData */}
      <div className="miscData">
        <h4>
          <strong>Banca: </strong>
          {banca ? banca : organization.short_name}
        </h4>
        <h4>
          <strong>Inscrições: </strong>
          {conversaoDia(registration_opening)} a{" "}
          {conversaoData(registration_deadline)}
        </h4>
        <h4>
          <strong>Taxa: </strong>
          {`${convertMoney(taxa)}`}
        </h4>
        <h4>
          <strong>Prova: </strong>
          {lista_de_datas.length > 0
            ? `${lista_de_datas[0]}/${lista_de_datas[1]}/${lista_de_datas[2]}`
            : "Não informado"}
        </h4>
        <div className="misclocation">
          <h4>
            <strong>Local: </strong>
            {local}
          </h4>
        </div>
      </div>
      {/* 6 parte - Botão */}
      {buttonExist && (
        <Link to={`/concurso/${slugLink}`}>
          <button className="confiraConcurso">
            <p>Confira o concurso </p>
          </button>
        </Link>
      )}
    </StyledCard>
  );
}

export default Card;
