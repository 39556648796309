import styled from "styled-components";

export const StyledInnerCard = styled.main`
  display: flex;
  flex-direction: column;
  gap: var(--Scale-5);
  /* width: 70%; */
  /* margin: var(--Scale-12) auto; */
  max-width: 134.4rem;
  margin: 7.2rem auto 9.6rem auto !important;
  min-height: calc(100vh - var(--NewFooterHeight) - 7.2rem);

  .flexHeader {
    display: flex;
    flex-direction: column;
    gap: var(--Scale-6, 48px);
    margin-bottom: 0.8rem;
  }
  .sectionRelative {
    position: relative;
  }

  .tittle {
    display: flex;
    flex-direction: column;
    gap: var(--Scale-2);
    .flexTittle {
      display: flex;
      gap: var(--Scale-4);
      align-items: center;
      .mobileLogo {
        display: none;
      }
      h1 {
        color: var(--Grey-900, #252a3d);
        /* Title/Large/Regular */
        font-family: "Work Sans";
        font-size: 3.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.72px;
      }
      img {
        align-self: center;
        height: 3.2rem;
      }
    }
  }
  .flexFigures {
    display: grid;
    grid-template-columns: 350px 128px 237px 247px 250px;
    gap: 8px;
  }
  .flexTittleStates {
    display: flex;
    flex-direction: column;
    gap: var(--Scale-2, 16px);
    height: var(--start-height);
    overflow: hidden;
    transition: height 0.5s ease;

    .tittle {
      color: var(--Grey-700, #33394d);
      font-family: "Work Sans";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.16px;
    }
    .flexListStates {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      .flexStateCity {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        max-height: 5.2766rem;

        .flexState {
          display: flex;
          align-items: center;
          gap: var(--Scale-2, 16px);

          .stateName {
            color: var(--Grey-700, #33394d);
            font-family: "Work Sans";
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: 0.16px;
          }
        }
        .city {
          color: var(--Grey-500, #606780);
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: 0.16px;
        }
      }
    }
  }

  .officeSection {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--Scale-2, 16px);

    .block {
      position: absolute;
      height: 100%;
      width: 2rem;
      background-color: #ffffff;
      z-index: 999;
      left: 100%;
      transform: translateX(-90%);
    }

    h2 {
      color: var(--Grey-700, #33394d);
      font-family: "Work Sans";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.16px;
    }

    p {
      color: var(--Grey-500, #606780);
      font-family: "Work Sans";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.16px;
      max-width: 100%;
      word-wrap: break-word;
    }
  }
  .showMoreButton {
    color: var(--Primary-500, #1b2cc1);
    font-family: "Work Sans";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.16px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: fit-content;
  }
  .slideCargos {
    display: flex;
  }

  @keyframes expand {
    from {
      height: var(--start-height);
    }
    to {
      height: var(--end-height);
    }
  }

  @keyframes desexpand {
    from {
      height: var(--end-height);
    }
    to {
      height: var(--start-height);
    }
  }

  .expand {
    animation: expand var(--animation-time) forwards;
  }

  .desexpand {
    animation: desexpand var(--animation-time) forwards;
  }

  .notices {
    h2 {
      color: var(--Grey-700, #33394d);
      font-family: "Work Sans";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.16px;
    }

    .notice {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      padding: 1.2rem var(--Scale-2, 16px) 1.2rem var(--Scale-0, 0px);
    }

    .pdfLink {
      color: var(--Primary-500, #1b2cc1);
      text-align: center;
      text-decoration: none;

      /* Text/Large/Regular */
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.16px;
      :visited {
        color: var(--Primary-500, #1b2cc1);
      }
    }
  }
  .cardInnerCard {
    width: 30rem !important;
    min-height: 48rem !important;
    max-height: 48rem !important;

    .tagNormal {
      p {
        color: #fff;
      }
    }
    .CardStickyC {
      h2 {
        color: var(--Grey-700);
        text-align: center;
        font-family: "Work Sans", sans-serif;
        font-size: 3.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2058rem;
        height: 4.5rem;
      }
    }
  }

  .slideContainerInnerCard {
    width: 100%;
    position: relative;
    margin-bottom: 8rem;
    overflow-y: visible !important;
    overflow: hidden;
    .swiper-button-prev {
      top: 0 !important;
      margin-top: 0 !important;
      height: 100% !important;
      left: 0 !important;
      background-color: white !important;
      width: 10rem !important;
      z-index: 10 !important;
      border: none;
    }
    .swiper-button-next {
      top: 0 !important;
      margin-top: 0 !important;
      height: 100% !important;
      right: 0rem !important;
      width: 10rem !important;
      background-color: white !important;
      z-index: 10 !important;
    }
    .swiper {
      padding: 0 10rem !important;
    }
    .swiper-wrapper {
      justify-content: flex-start !important;
      width: 100%;
      margin: 0;
      padding: 2rem;
      /* overflow: hidden; */
    }
  }
  @media (max-width: 1390px) {
    padding: 4.8rem 3.2rem 0;
    .cardInnerCard {
      width: 28rem !important;
    }
  }
  @media (max-width: 1333px) {
    .cardInnerCard {
      width: 26rem !important;
    }
  }
  @media (max-width: 1276px) {
    .cardInnerCard {
      width: 30rem !important;
    }
  }

  @media (max-width: 1300px) {
    .flexFigures {
      grid-template-columns: 350px 128px 237px;
    }
    .gridItems5 {
      grid-column: 2/4;
    }
  }
  @media (max-width: 1014px) {
    .cardInnerCard {
      width: 26rem !important;
    }
  }

  @media (max-width: 939px) {
    .cardInnerCard {
      width: 80% !important;
    }
  }

  @media (max-width: 823px) {
    .flexFigures {
      grid-template-columns: 350px 237px;
      grid-template-rows: repeat(3, 1fr);
    }
  }

  @media (max-width: 671px) {
    .flexFigures {
      grid-template-columns: 350px;
    }
  }
  @media (max-width: 646px) {
    .slideContainerInnerCard {
      .swiper-button-prev {
        width: 5rem !important;
      }
      .swiper-button-next {
        width: 5rem !important;
        /* &::after {
          font-size: 2rem;
        } */
      }
      .swiper {
        padding: 0 5rem !important;
      }
    }
    .officeSection {
      .block {
        width: 1rem;
      }
    }
  }
  @media (max-width: 540px) {
    .flexTittle {
      flex-direction: column;
      gap: 16px !important;
      align-items: start !important;
      img {
        align-self: start !important;
      }
      .desktopLogo {
        display: none;
      }

      h1 {
        overflow-wrap: break-word;
      }
    }
    .mobileLogo {
      display: block !important;
    }
  }
  .textoDeMeioDeTela {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3.2rem;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.16px;
  }

  @media (max-width: 518px) {
    .cardInnerCard {
      width: 75% !important;
      min-height: fit-content !important;
      max-height: fit-content !important;
      .CardStickyC {
        text-align: center;
      }
      .flexSuperior {
        flex-direction: column;
        align-items: flex-start;
      }
      .flexTags {
        align-items: flex-start;
      }
    }

    .slideContainerInnerCard {
      .swiper {
        padding: 0 3rem !important;
      }
      .swiper-button-prev {
        width: 3rem !important;
        &::after {
          font-size: 3rem !important;
        }
      }
      .swiper-button-next {
        width: 3rem !important;
        &::after {
          font-size: 3rem !important;
        }
      }
    }
    .officeSection {
      .block {
        width: 0.1rem;
      }
    }
  }
  @media (max-width: 404px) {
    .flexFigures {
      grid-template-columns: 300px;
    }
    .cardInnerCard {
      width: 65% !important;
    }
  }
`;
