import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signup } from "../actions/auth";
import axios from "axios";
import { StyledSignUp } from "../styles/SignUp";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const schema = Yup.object().shape({
  username: Yup.string().required("* É necessário inserir um nome válido."),
  lastName: Yup.string().required(
    "* É necessário inserir um sobrenome válido."
  ),
  email: Yup.string()
    .email("* É necessário inserir um e-mail válido.")
    .required("* Email é obrigatório"),
  password: Yup.string()
    .min(8, "* A senha deve ter no mínimo 8 caracteres")
    .matches(/(?=.*[0-9])/, "* A senha deve conter pelo menos um número")
    .matches(
      /(?=.*[@$!%*?&])/,
      "* A nova senha deve conter pelo menos um caractere especial"
    )
    .matches(
      /(?=.*[a-z])/,
      "* A senha deve conter pelo menos uma letra minúscula"
    )
    .matches(/^(?!.*\b(?:123456|password|qwerty)\b).*$/, "* Senha muito fraca")
    .required("* Senha é obrigatória"),
  re_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "* As senhas devem ser iguais.")
    .required("* Confirmação de senha é obrigatória"),
  termos: Yup.boolean()
    .oneOf(
      [true],
      "* é necessário aceitar os termos de serviço para se registrar."
    )
    .required("* é necessário aceitar os termos de serviço para se registrar."),
});

const SignUp = ({ signup, isAuthenticated }) => {
  document.title = `Cadastro - Alô Concursos`;
  const URL = process.env.REACT_APP_API_URL;
  const [accountCreated, setAccountCreated] = useState(false);
  const [designRegisterButton, setDesignRegisterButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorFormVariable, setErrorFormVariable] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
      lastName: "",
      email: "",
      password: "",
      re_password: "",
    },
  });

  const onSubmit = async (data, e) => {
    setDesignRegisterButton(true);
    e.preventDefault();
    const { username, lastName, email, password, re_password } = data;
    const response = await signup(
      username,
      lastName,
      email,
      password,
      re_password
    );

    if (response.success) {
      setAccountCreated(true);
      setErrorMessage(null);
    } else {
      setDesignRegisterButton(false);
      setErrorFormVariable(true);
      setErrorMessage("Já existe usuário com este e-mail registrado.");
    }
  };

  const continueWithGoogle = async () => {
    try {
      const res = await axios.get(
        `${URL}/auth/o/google-oauth2/?redirect_uri=${URL}`
      );

      window.location.replace(res.data.authorization_url);
    } catch (err) {
      console.error("Erro ao tentar registrar com o Google", err);
    }
  };

  useEffect(() => {
    if (errors.email) {
      setErrorFormVariable(true);
    } else {
      setErrorFormVariable(false);
      setErrorMessage(null);
    }
  }, [errors.email]);

  const continueWithFacebook = async () => {
    try {
      const res = await axios.get(
        `${URL}/auth/o/facebook/?redirect_uri=${URL}`
      );

      window.location.replace(res.data.authorization_url);
    } catch (err) {
      console.error("Erro ao tentar registrar com o Facebook", err);
    }
  };

  return (
    <StyledSignUp>
      {accountCreated ? (
        <div className="OldSignUp" style={{ marginBottom: "4.8rem" }}>
          <svg
            className="svgScreen"
            xmlns="http://www.w3.org/2000/svg"
            width="96"
            height="96"
            viewBox="0 0 96 96"
            fill="none"
          >
            <g clipPath="url(#clip0_2463_206)">
              <path
                d="M48 0C21.52 0 0 21.52 0 48C0 74.48 21.52 96 48 96C74.48 96 96 74.48 96 48C96 21.52 74.48 0 48 0ZM68.68 46.48L60 53.56L63.6 64.48C64.08 65.96 63.6 67.6 62.36 68.52C61.12 69.48 59.44 69.52 58.12 68.68L48.04 62.12L38.12 68.76C37.52 69.16 36.8 69.36 36.08 69.36C35.32 69.36 34.52 69.12 33.88 68.64C32.64 67.72 32.12 66.08 32.6 64.6L36.04 53.56L27.32 46.48C26.16 45.48 25.72 43.88 26.24 42.44C26.76 41 28.16 40.04 29.68 40.04H40.68L44.56 29.6C45.08 28.16 46.48 27.2 48 27.2C49.52 27.2 50.92 28.16 51.44 29.6L55.32 40.04H66.32C67.84 40.04 69.24 41 69.76 42.44C70.28 43.88 69.84 45.52 68.68 46.52V46.48Z"
                fill="#5FCE1D"
              />
            </g>
            <defs>
              <clipPath id="clip0_2463_206">
                <rect width="96" height="96" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div className="flexTittleText">
            <h1>Cadastro realizado com sucesso</h1>
            <p>
              Enviamos um e-mail com um link e instruções para você{" "}
              <span>verificar seu e-mail.</span> <br />
              <br /> A confirmação serve para garantir que temos o seu e-mail
              correto cadastrado em nosso sistema. Verifique sua caixa de spam
              ou promoções, caso não apareça na sua caixa principal do seu
              gerenciador de e-mail.
            </p>
          </div>
        </div>
      ) : (
        <div className="OldSignUp">
          <h2>Faça seu registro</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="usernameR">Nome</label>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <input
                    className={
                      errors.username
                        ? "form-control errorForm"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Insira seu primeiro nome"
                    id="usernameR"
                    {...field}
                  />
                )}
              />
              {errors.username && (
                <div className="error">{errors.username.message}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="last_nameR">Sobrenome</label>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <input
                    className={
                      errors.lastName
                        ? "form-control errorForm"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Insira seu sobrenome"
                    id="last_nameR"
                    {...field}
                  />
                )}
              />
              {errors.lastName && (
                <div className="error">{errors.lastName.message}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="emailR">Email</label>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <input
                    id="emailR"
                    className={`form-control ${
                      errorFormVariable && "errorForm"
                    }`}
                    type="email"
                    placeholder="Insira seu e-mail"
                    {...field}
                  />
                )}
              />
              {errors.email && (
                <div className="error">{errors.email.message}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="passwordR">Senha</label>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <input
                    className={
                      errors.password
                        ? "form-control errorForm"
                        : "form-control"
                    }
                    id="passwordR"
                    type="password"
                    placeholder="••••••••"
                    {...field}
                  />
                )}
              />
              {errors.password && (
                <div className="error">{errors.password.message}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="re_passwordR">Confirme sua senha</label>
              <Controller
                name="re_password"
                control={control}
                render={({ field }) => (
                  <input
                    id="re_passwordR"
                    className={
                      errors.re_password
                        ? "form-control errorForm"
                        : "form-control"
                    }
                    type="password"
                    placeholder="••••••••"
                    {...field}
                  />
                )}
              />
              {errors.re_password && (
                <div className="error">{errors.re_password.message}</div>
              )}
              <div>
                <div className="flexTermos">
                  <Controller
                    name="termos"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input id="termos" type="checkbox" {...field} />
                        <label htmlFor="termos">
                          Eu li e aceito os{" "}
                          <Link to={"/termos"}>termos de serviço</Link>
                        </label>
                      </>
                    )}
                  />
                </div>
                {errors.termos && (
                  <div className="error">{errors.termos.message}</div>
                )}
              </div>
            </div>
            {errorMessage && <div className="error">{errorMessage}</div>}
            {designRegisterButton ? (
              <button style={{ display: "flex", gap: "1.6rem" }}>
                <p>Registrando</p>
                <div style={{ alignSelf: "flex-end" }} className="loader" />
              </button>
            ) : (
              <button type="submit">Registrar</button>
            )}
          </form>

          <p className="Register">
            Já possui uma conta? <Link to="/login">Fazer login</Link>
          </p>
        </div>
      )}
      {accountCreated && (
        <p className="newWarning">
          <span>Não recebeu o e-mail?</span> Se em 5 minutos você não receber,
          entre em contato através do e-mail{" "}
          <a href="mailto:admin@aloconcursos">admin@aloconcursos.com</a>
        </p>
      )}
    </StyledSignUp>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { signup })(SignUp);
