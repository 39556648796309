import filterImg from "../assets/filterImg.svg";
import FilterSubMenu from "../UI/FilterSubMenu";
import { StyledFilter } from "../styles/Filter";
import Slider from "react-slider";
import { useEffect, useState } from "react";

const MIN = 0;
const MAX = 50000;
// https://www.aloconcursos.com/contest/contest/?wage_range=12000%2C16000
const MINVacancy = 0;
const MAXVacancy = 1000;

function Filter({
  estados,
  nivelEducacional,
  faseConcurso,
  vagas,
  salario,
  setPreInscricao,
  preInscricao,
  setInscricoesAbertas,
  inscricoesAbertas,
  setInscricoesEncerradas,
  inscricoesEncerradas,
  setSuperior,
  superior,
  setFundamental,
  fundamental,
  setMedio,
  medio,
  setMestrado,
  mestrado,
  setDoutorado,
  doutorado,
  cadastroReserva,
  setCadastroReserva,
  vagaUm,
  setVagaUm,
  vagaDoisDez,
  setVagaDoisDez,
  vagaDez,
  setVagaDez,
  vagaVinteCinco,
  setVagaVinteCinco,
  vagaCinquenta,
  setVagaCinquenta,
  vagaCem,
  setVagaCem,
  semRendimentos,
  setSemRendimentos,
  salario1,
  setSalario1,
  salario2,
  setSalario2,
  salario3,
  setSalario3,
  salario4,
  setSalario4,
  salario5,
  setSalario5,
  salario6,
  setSalario6,
  salario7,
  setSalario7,
  salario8,
  setSalario8,
  salario9,
  setSalario9,
  salario10,
  setSalario10,
  salario11,
  setSalario11,
  salario12,
  setSalario12,
  salario13,
  setSalario13,
  salario14,
  setSalario14,
  nacional,
  saoPaulo,
  rioDeJaneiro,
  distritoFederal,
  acre,
  alagoas,
  amapa,
  amazonas,
  bahia,
  ceara,
  espiritoSanto,
  goias,
  maranhao,
  matoGrosso,
  matoGrossoDoSul,
  minasGerais,
  para,
  paraiba,
  parana,
  pernambuco,
  piaui,
  rioGrandeDoNorte,
  rioGrandeDoSul,
  rondonia,
  roraima,
  santaCatarina,
  sergipe,
  tocantins,
  setNacional,
  setSaoPaulo,
  setRioDeJaneiro,
  setDistritoFederal,
  setAcre,
  setAlagoas,
  setAmapa,
  setAmazonas,
  setBahia,
  setCeara,
  setEspiritoSanto,
  setGoias,
  setMaranhao,
  setMatoGrosso,
  setMatoGrossoDoSul,
  setMinasGerais,
  setPara,
  setParaiba,
  setParana,
  setPernambuco,
  setPiaui,
  setRioGrandeDoNorte,
  setRioGrandeDoSul,
  setRondonia,
  setRoraima,
  setSantaCatarina,
  setSergipe,
  setTocantins,
  setWageValue,
  setVacancyValue,
}) {
  function limparFiltros() {
    setPreInscricao(false);
    setInscricoesAbertas(false);
    setInscricoesEncerradas(false);

    setSuperior(false);
    setFundamental(false);
    setMedio(false);
    setMestrado(false);
    setDoutorado(false);

    setCadastroReserva(false);
    setVagaUm(false);
    setVagaDoisDez(false);
    setVagaDez(false);
    setVagaVinteCinco(false);
    setVagaCinquenta(false);
    setVagaCem(false);

    setSemRendimentos(false);
    setSalario1(false);
    setSalario2(false);
    setSalario3(false);
    setSalario4(false);
    setSalario5(false);
    setSalario6(false);
    setSalario7(false);
    setSalario8(false);
    setSalario9(false);
    setSalario10(false);
    setSalario11(false);
    setSalario12(false);
    setSalario13(false);
    setSalario14(false);

    setNacional(false);
    setSaoPaulo(false);
    setRioDeJaneiro(false);
    setDistritoFederal(false);
    setAcre(false);
    setAlagoas(false);
    setAmapa(false);
    setAmazonas(false);
    setBahia(false);
    setCeara(false);
    setEspiritoSanto(false);
    setGoias(false);
    setMaranhao(false);
    setMatoGrosso(false);
    setMatoGrossoDoSul(false);
    setMinasGerais(false);
    setPara(false);
    setParaiba(false);
    setParana(false);
    setPernambuco(false);
    setPiaui(false);
    setRioGrandeDoNorte(false);
    setRioGrandeDoSul(false);
    setRondonia(false);
    setRoraima(false);
    setSantaCatarina(false);
    setSergipe(false);
    setTocantins(false);
  }

  const [values, setValues] = useState([MIN, MAX]);
  const [vacancy, setVacancy] = useState([MINVacancy, MAXVacancy]);

  useEffect(() => {
    setWageValue(values);
    setVacancyValue(vacancy);
  }, [values, vacancy]);

  function formatMil(num) {
    return num >= 1000 ? num.toLocaleString("pt-BR") : num.toString();
  }

  return (
    <StyledFilter className="Filter">
      <div className="flex">
        <img src={filterImg} alt="Filter" />
        <h3>Filtrar resultados</h3>
      </div>
      <button onClick={() => limparFiltros()} className="filterClean">
        Limpar filtros
      </button>
      <div className="gridFilterMobile">
        {estados ? (
          <FilterSubMenu
            seta={"up"}
            nome="Estado"
            submenu={estados}
            nacional={nacional}
            saoPaulo={saoPaulo}
            rioDeJaneiro={rioDeJaneiro}
            distritoFederal={distritoFederal}
            acre={acre}
            alagoas={alagoas}
            amapa={amapa}
            amazonas={amazonas}
            bahia={bahia}
            ceara={ceara}
            espiritoSanto={espiritoSanto}
            goias={goias}
            maranhao={maranhao}
            matoGrosso={matoGrosso}
            matoGrossoDoSul={matoGrossoDoSul}
            minasGerais={minasGerais}
            para={para}
            paraiba={paraiba}
            parana={parana}
            pernambuco={pernambuco}
            piaui={piaui}
            rioGrandeDoNorte={rioGrandeDoNorte}
            rioGrandeDoSul={rioGrandeDoSul}
            rondonia={rondonia}
            roraima={roraima}
            santaCatarina={santaCatarina}
            sergipe={sergipe}
            tocantins={tocantins}
            setNacional={setNacional}
            setSaoPaulo={setSaoPaulo}
            setRioDeJaneiro={setRioDeJaneiro}
            setDistritoFederal={setDistritoFederal}
            setAcre={setAcre}
            setAlagoas={setAlagoas}
            setAmapa={setAmapa}
            setAmazonas={setAmazonas}
            setBahia={setBahia}
            setCeara={setCeara}
            setEspiritoSanto={setEspiritoSanto}
            setGoias={setGoias}
            setMaranhao={setMaranhao}
            setMatoGrosso={setMatoGrosso}
            setMatoGrossoDoSul={setMatoGrossoDoSul}
            setMinasGerais={setMinasGerais}
            setPara={setPara}
            setParaiba={setParaiba}
            setParana={setParana}
            setPernambuco={setPernambuco}
            setPiaui={setPiaui}
            setRioGrandeDoNorte={setRioGrandeDoNorte}
            setRioGrandeDoSul={setRioGrandeDoSul}
            setRondonia={setRondonia}
            setRoraima={setRoraima}
            setSantaCatarina={setSantaCatarina}
            setSergipe={setSergipe}
            setTocantins={setTocantins}
          />
        ) : null}

        {nivelEducacional ? (
          <FilterSubMenu
            seta={"down"}
            nome="Escolaridade"
            submenu={nivelEducacional}
            setSuperior={setSuperior}
            superior={superior}
            setFundamental={setFundamental}
            fundamental={fundamental}
            setMedio={setMedio}
            medio={medio}
            setMestrado={setMestrado}
            mestrado={mestrado}
            setDoutorado={setDoutorado}
            doutorado={doutorado}
          />
        ) : null}

        {faseConcurso ? (
          <FilterSubMenu
            seta={"down"}
            nome="Etapas"
            submenu={faseConcurso}
            setPreInscricao={setPreInscricao}
            preInscricao={preInscricao}
            setInscricoesAbertas={setInscricoesAbertas}
            inscricoesAbertas={inscricoesAbertas}
            setInscricoesEncerradas={setInscricoesEncerradas}
            inscricoesEncerradas={inscricoesEncerradas}
          />
        ) : null}

        {vagas ? (
          <div className="vacancyRanged">
            <h4 className="tittle">Vagas</h4>
            <span className="rangegChoose">
              {vacancy[0] === 0 ? "Cadastro de Reserva" : vacancy[0]} -{" "}
              {vacancy[1] === 1000 ? "1000 +" : vacancy[1]}
            </span>
            <Slider
              className="slider"
              value={vacancy}
              min={MINVacancy}
              max={MAXVacancy}
              onChange={setVacancy}
            />
          </div>
        ) : null}

        {salario ? (
          <div className="wageRanged">
            <h4 className="tittle">Salário</h4>
            <span className="rangegChoose">
              R$ {formatMil(values[0])} - R${" "}
              {values[1] === 50000 ? "50.000 +" : formatMil(values[1])}
            </span>
            <Slider
              className="slider"
              value={values}
              min={MIN}
              max={MAX}
              onChange={setValues}
            />
          </div>
        ) : null}
      </div>
    </StyledFilter>
  );
}

export default Filter;
